<template>
  <div style="margin: 0vw 0vw; ">
<!--    <div style="text-align: center; padding-top: 10vw; padding-bottom: 8vw; background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);">
      <h1 style="font-size: 50px; font-weight: 1000 ; color: #F5F5F5; text-emphasis: black">
        FREQUENTLY ASKED QUESTIONS
      </h1>
    </div>-->

<!--   FAQ card about NFT -->
  <div class="hidden sm:block" style="margin-top: 30vh;margin-left: 5vw; margin-right: 5vw;">
<!--    center-->
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        1.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: What is an NFT?
          <br>
          <br>

          An NFT, or non-fungible token, is a unique digital asset that represents ownership of a specific item, such as artwork or collectibles.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        2.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: How do I purchase an NFT from this collection?
          <br>
          <br>

          A: You can purchase an NFT from this collection by using a cryptocurrency wallet that supports NFT purchases, such as Phantom
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        3.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: Are there any limitations on how I can use or display my NFT?
          <br>
          <br>

          A: When purchasing a Mirai NFT you will get full creative licence for your NFT so feel free to monetise your NFT in any shape or form you want.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        4.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: Is the artwork in this collection original?
          <br>
          <br>

          A: Yes, the artwork in this collection is original and has been created specifically by a up and coming digital artist from Italy especially for this NFT release. The art style is inspired by cyberpunk aesthetics.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        5.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: How many NFTs are in this collection?
          <br>
          <br>

          A: There are a total of 3939 NFTs in this collection.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        6.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: Can I sell my NFT from this collection in the future?
          <br>
          <br>

          A: Yes, you can sell or gift your NFT at any point in time
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        7.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: How will I receive my NFT after I purchase it?
          <br>
          <br>

          A: After you purchase an NFT from this collection, it will be stored in your cryptocurrency wallet. You can view and display your NFT using a compatible wallet or browser extension, such as Phantom.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        8.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: Is there a limit to how many NFTs I can purchase from this collection?
          <br>
          <br>

          A: There are no limits on how many NFTs you can purchase purely depending on supply and demand.. It's a good idea to keep an eye on the website for updates on availability and to act quickly if you see an NFT you're interested in purchasing.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 576px; font-size: 40px">
        9.
      </h3>
      <div class="gradient-filler" style="width: 576px; margin-top: 2vh; "></div>
      <div style="float: right;">
        <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: -3vh; margin-bottom: 15vh; ">
          Q: Will there be any future releases or additions to this NFT collection?
          <br>
          <br>

          A: Yes, we have plans for future releases or additions to this NFT collection but we will work closely with the Mirai Community to make major decisions. Keep an eye on the website and the Mirai twitter channel for updates and announcements.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>
  <div class="sm:hidden" style="margin-top: 0vh;margin-left: 5vw; padding-top: 12vh; margin-right: 5vw;">
<!--    center-->
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        1.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style="">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: What is an NFT?
          <br>
          <br>

          An NFT, or non-fungible token, is a unique digital asset that represents ownership of a specific item, such as artwork or collectibles.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        2.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: How do I purchase an NFT from this collection?
          <br>
          <br>

          A: You can purchase an NFT from this collection by using a cryptocurrency wallet that supports NFT purchases, such as Phantom
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        3.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: Are there any limitations on how I can use or display my NFT?
          <br>
          <br>

          A: When purchasing a Mirai NFT you will get full creative licence for your NFT so feel free to monetise your NFT in any shape or form you want.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        4.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: Is the artwork in this collection original?
          <br>
          <br>

          A: Yes, the artwork in this collection is original and has been created specifically by a up and coming digital artist from Italy especially for this NFT release. The art style is inspired by cyberpunk aesthetics.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        5.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: How many NFTs are in this collection?
          <br>
          <br>

          A: There are a total of 3939 NFTs in this collection.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        6.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: Can I sell my NFT from this collection in the future?
          <br>
          <br>

          A: Yes, you can sell or gift your NFT at any point in time
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        7.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: How will I receive my NFT after I purchase it?
          <br>
          <br>

          A: After you purchase an NFT from this collection, it will be stored in your cryptocurrency wallet. You can view and display your NFT using a compatible wallet or browser extension, such as Phantom.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        8.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style=" ">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: Is there a limit to how many NFTs I can purchase from this collection?
          <br>
          <br>

          A: There are no limits on how many NFTs you can purchase purely depending on supply and demand.. It's a good idea to keep an eye on the website for updates on availability and to act quickly if you see an NFT you're interested in purchasing.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div>
      <h3 style="color: white;width: 84vw; font-size: 40px">
        9.
      </h3>
      <div class="gradient-filler" style="width: 84vw; margin-top: 2vh; "></div>
      <div style="">
        <div  class="text-white whitespace-pre-line  paragraph-m " style="width: 84vw;  margin-top: 5vh;; margin-bottom: 15vh; ">
          Q: Will there be any future releases or additions to this NFT collection?
          <br>
          <br>

          A: Yes, we have plans for future releases or additions to this NFT collection but we will work closely with the Mirai Community to make major decisions. Keep an eye on the website and the Mirai twitter channel for updates and announcements.
          <br>
          <br>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>
  <div style="margin-top: 15vh;">
<!--    <VueFaqAccordion-->
<!--        :items="myItems"-->
<!--    />-->
  </div>
  </div>

<!--  add faq parts-->
</template>


<script>

export default {
  name: "FAQ",
  components: {
  },
  data() {
    return {
      textarea: "FAQ waiting for a new question...",
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: 'FAQ waiting for a new question...'
      },
      items: [
        {
          title: 'How many time zones are there in all?',
          value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
          category: 'Tab-1'
        },
        {
          title: 'How long is a day and year on Venus?',
          value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
          category: 'Tab-2'
        },
        {
          title: 'What animal smells like popcorn?',
          value: 'Binturongs smell like popcorn.',
          category: 'Tab-2'
        }
      ],
      options: [{value: 10, name: "Gaming"}, {value: 20, name: "Real Life Collab"}, {value: 30, name: "Staking"}, {value: 40, name: "Voting Rights"}, {value: 50, name: "NFT Upgrades"}]
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    }
  }
}

</script>
<style >
body {
  /*background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);*/
  background: #000;
}
.paragraph {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  color: #F5F5F5;
}
.paragraph-m {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 108px;
  /* or 156% */

  color: #F5F5F5;
}
.gradient-filler {
  margin-top: 7vh;
  width: 25vw;
  height: 0.25vh;
  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
}
.brown-glass-card {
  z-index: 2000;
  /*background: rgba(123, 92, 92, 0.37);*/
  hyphens: manual;
  border-radius: 25px;
  padding: 2vw;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
  border: 1px solid rgba(214, 200, 200, 0.09);
}

.el-card {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.question {
  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
  background-clip: text;
  color: transparent;
  font-weight: bold;
  font-size: 28px;
  margin: 28px
}
.answer {
  background: linear-gradient(47.3deg, #DB97AE 0%, #FFBF85 127.58%);  background-clip: text;
  color: transparent;
  font-weight: 700;
  font-size: 20px;
  margin: 28px
}
</style>


import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const showText = ref(false);
    return {
      showText,
    };
  },
});

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Farmer from '@/views/Farmer.vue';
import Manager from '@/views/Manager.vue';
import Home from '@/views/Home.vue';
import HomePage from '@/views/HomePage.vue';
import Lore from '@/views/Lore.vue';
import Ecosystem from '@/views/Ecosystem.vue';
import Utilities from '@/views/Utilities.vue';
import Faq from '@/views/FAQ.vue';
import component from "*.vue";

const routes: Array<RouteRecordRaw> = [
  // add component of homepage here
  {
    path: '/',
    name: 'Homepage',
    component: HomePage
  },
  {
    path: '/homeForFarmer',
    name: 'Home',
    component: Home,
  },
  {
    path: '/manager',
    name: 'Farm Manager',
    component: Manager,
  },
  {
    path: '/staking',
    name: 'Farmer',
    component: Farmer,
  },
  {
    path: '/lore',
    name: 'Lore',
    component: Lore,
  },
  {
    path: '/ecosystem',
    name: 'Ecosystem',
    component: Ecosystem,
  },
  {
    path: '/utilities',
    name: 'Utilities',
    component: Utilities,
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
  },
  {
    path: '/staking',
    name: 'Farmer',
    component: Farmer,
  },
  {
    path: '/staking',
    name: 'Farmer',
    component: Farmer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <!--    <div class="bg-white single-page">
        <div class="brand text-center content-center top-1/2 left-1/2 ">
           <p>Not All NFTs</p>
          <p>Are Created</p>
          <p class="oval-orange">Equal</p>
        </div>
        &lt;!&ndash; add an orange oval &ndash;&gt;
        &lt;!&ndash; <div class="oval-orange"></div> &ndash;&gt;
      </div>-->
  <el-carousel class="hidden md:block" height="100vh">
    <!--    <el-carousel-item style="min-height: 100vh;">
          <div class="bg-eco-1 single-page">
            <div
                style="position: relative; z-index: 20; text-align: center; font-size: 18rem; color: white; font-weight: 800;">
              CLUB
            </div>
            <div
                style="position: relative; z-index: 20; top: -9vw; text-align: center; font-size: 18rem; color: white; font-weight: 800;">
              MIRAI
            </div>
            <img :src="redscar" alt=""
                 style="position: absolute; top: 0; z-index: 0; height: 100vh; left: 50vw; transform: translate(-50%, 0)">
          </div>
        </el-carousel-item>-->
    <el-carousel-item style="min-height: 100vh;">

      <div class="bg-eco-1 single-page">
        <div
            class="not-pic">
          Mirai is not just a profile picture
        </div>
        <!--        <div-->
        <!--            style="position: relative; z-index: 20; left: 5vw; top: 30vh; text-align: left; font-size: 1.5vw; color: white; font-weight: 300; width: 30vw;">-->
        <!--          Unlock the world of Club Mirai and enjoy #insane value and entertainment-->
        <!--        </div>-->
        <div
            class="unlockBanner">
          Unlock the world of Club Mirai and enjoy #insane value and entertainment
        </div>
        <!--        <div style="position: relative; z-index: 20; text-align: center; font-size: 8rem; color: white; font-weight: 800;">-->
        <!--         -->
        <!--        </div>-->
        <img :src="space" alt="" style="position: absolute; top: 0; z-index: 0; height: 960px; min-width: 100vw; max-height: 100vh; width: 1680px">
        <img :src="astronaut" alt="" style="position: absolute;
width: 676px;
height: 820px;
left: 952px;
top: 140px;z-index: 200;">
      </div>
    </el-carousel-item>
  </el-carousel>
  <el-carousel class="md:hidden" arrow="never" height="100vh">
    <!--    <el-carousel-item style="min-height: 100vh;">
          <div class="bg-eco-1 single-page">
            <div
                style="position: relative; z-index: 20; text-align: center; font-size: 18rem; color: white; font-weight: 800;">
              CLUB
            </div>
            <div
                style="position: relative; z-index: 20; top: -9vw; text-align: center; font-size: 18rem; color: white; font-weight: 800;">
              MIRAI
            </div>
            <img :src="redscar" alt=""
                 style="position: absolute; top: 0; z-index: 0; height: 100vh; left: 50vw; transform: translate(-50%, 0)">
          </div>
        </el-carousel-item>-->
    <el-carousel-item style="min-height: 100vh;">
      <div class="bg-eco-1 single-page">
        <div
            style="position: relative; z-index: 20; left: 3vw; top: 12vh; text-align: left; font-size: 11vw; color: white; font-weight: 500; width: 83vw;">
          Mirai is not just a profile picture
        </div>
        <div
            style="position: relative; z-index: 20; left: 5vw; top: 15vh; text-align: left; font-size: 5.5vw; color: white; font-weight: 300; width: 83vw;">
          Unlock the world of Club Mirai and enjoy #insane value and entertainment
        </div>
        <!--        <div style="position: relative; z-index: 20; text-align: center; font-size: 8rem; color: white; font-weight: 800;">-->
        <!--         -->
        <!--        </div>-->
        <img :src="space" alt="" style="position: absolute; top: 0; z-index: 0; height: 100vh; width: 100vw">
        <img :src="astronaut" alt="" style="position: absolute; bottom: 0; right: 0; z-index: 20; width: 85vw">
      </div>
    </el-carousel-item>
    <!--    <el-carousel-item style="min-height: 100vh;">
          <div class="bg-eco-1 single-page">
            <div
                style="position: relative; z-index: 20; left: 5vw; top: 20vh; text-align: left; font-size: 3.5vw; color: white; font-weight: 800; width: 30vw;">
              Not all NFTs are created equal
            </div>
            &lt;!&ndash;        <div style="position: relative; z-index: 20; text-align: center; font-size: 8rem; color: white; font-weight: 800;">&ndash;&gt;
            &lt;!&ndash;         &ndash;&gt;
            &lt;!&ndash;        </div>&ndash;&gt;
            <img :src="desert" alt="" style="position: absolute; top: 0; z-index: 0; height: 100vh; width: 100vw">
          </div>
        </el-carousel-item>-->
  </el-carousel>

  <div id="utility" class="sticky w-full hidden">
    <!--    <section class="wow slideInUp" style="position: absolute; z-index: 10" data-wow-duration="1s" data-wow-delay="0.25s">-->
    <!--      <img :src="HeroBanner" alt="" style="width: 100vw; margin: 0 auto">-->
    <!--    </section>-->
    <!--    <section class="wow slideInUp" style="position: absolute; z-index: 20" data-wow-duration="1s" data-wow-delay="0.25s">-->
    <!--      <img :src="HeroBanner" alt="" style="width: 100vw; margin: 0 auto">-->
    <!--    </section>-->
    <section class="wow slideInUp" style="" data-wow-duration="1s" data-wow-delay="0.25s">
      <img :src="HeroBanner" alt="" style="width: 100vw; margin: 0 auto">
      <div
          style="position: absolute; top: 0; left: 0; width: 20vw; height: 6vw; background: linear-gradient(47.3deg, #DB97AE 0%, #FFBF85 127.58%);;">
        <div style="margin: auto auto; width: 15vw; height: 5vw;">
          <img :src="bulb" alt="">
          Utilities
        </div>

      </div>
    </section>
    <section class="wow slideInUp" style="" data-wow-duration="1s" data-wow-delay="0.25s">
      <img :src="HeroBanner" alt="" style="width: 100vw; margin: 0 auto">
      <div
          style="position: absolute; top: 0; left: 0; width: 20vw; background: linear-gradient(47.3deg, #DB97AE 0%, #FFBF85 127.58%);;">
        <img :src="bulb" alt="">
        <span style="float: right; vertical-align: center; margin: auto 2vw; ">Utilities</span>
      </div>
    </section>
  </div>
  <!--  todo:layout-->
  <!--  todo:mobile adaptation-->
  <!--  todo:select one transition-->
  <div class="hidden md:block" @scroll="bgTransition">
    <div  height="900px" class="single-page wow slideInUp" style="background: #F7F4EF;">
      <div >
        <div style="position: relative; width: 476px;height: 32px;
left: 52px;
top: 54px;

font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

display: flex;
align-items: flex-end;

/* Black 01 */

color: #000000;">
          Utilities
        </div>

        <div
            style="position: relative; left: 52px; top: 25vw; z-index: 50; color: black; width: 30vw;  ">
          <div style="font-size: 1.5vw">
            1. Gaming
          </div>
          <div>

          </div>
          <div style="font-size: 1vw; margin-top: .5vw;  ">
            Play, network, win. We will host regular tournaments to keep you engaged.
          </div>
          <div style="border-bottom: 3px black solid; height: 5px; margin-top: 1vw; margin-bottom: .5vw;"></div>
          <div style="font-size: 1.5vw;color: #292929; opacity: 0.12;">
            2. Real Life Collab
            <div style="border-bottom: 3px #292929 solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
          </div>
          <div style="font-size: 1.5vw;color: #292929; opacity: 0.12;">
            3. Staking
            <div style="border-bottom: 3px #292929 solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
          </div>
          <div style="font-size: 1.5vw;color: #292929; opacity: 0.12;">
            4. Voting Rights
            <div style="border-bottom: 3px #292929 solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
          </div>
          <div style="font-size: 1.5vw;color: #292929; opacity: 0.12;">
            5. NFT Upgrades
            <div style="border-bottom: 3px #292929 solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
          </div>
        </div>
<!--        <div style="position: absolute; top: 40vw; left: 2vw; width: 40vw; background: none;">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="1. Gaming" name="1">
              <div>
                Play, network, win. We will host regular tournaments to keep you engaged.
              </div>
            </el-collapse-item>
            <el-collapse-item title="2. Real Life Collab" name="2">
              <div>
                Play, network, win. We will host regular tournaments to keep you engaged.
              </div>
            </el-collapse-item>
            <el-collapse-item title="3. Staking" name="3">
              <div>
                Play, network, win. We will host regular tournaments to keep you engaged.
              </div>
            </el-collapse-item>
            <el-collapse-item title="4. Voting Rights" name="4">
              <div>
                Play, network, win. We will host regular tournaments to keep you engaged.
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>-->
        <img :src="DaxGaming" alt="" style="position: absolute; z-index: 50; width: 30vw;
left: 60vw;
top: 62vw;
">
        <div>
          <el-collapse v-model="activeName" class="hidden" accordion>
            <el-collapse-item title="Consistency" name="1">
              <div>
                Consistent with real life: in line with the process and logic of real
                life, and comply with languages and habits that the users are used to;
              </div>
              <div>
                Consistent within interface: all elements should be consistent, such
                as: design style, icons and texts, position of elements, etc.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Feedback" name="2">
              <div>
                Operation feedback: enable the users to clearly perceive their
                operations by style updates and interactive effects;
              </div>
              <div>
                Visual feedback: reflect current state by updating or rearranging
                elements of the page.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Efficiency" name="3">
              <div>
                Simplify the process: keep operating process simple and intuitive;
              </div>
              <div>
                Definite and clear: enunciate your intentions clearly so that the
                users can quickly understand and make decisions;
              </div>
              <div>
                Easy to identify: the interface should be straightforward, which helps
                the users to identify and frees them from memorizing and recalling.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Controllability" name="4">
              <div>
                Decision making: giving advices about operations is acceptable, but do
                not make decisions for the users;
              </div>
              <div>
                Controlled consequences: users should be granted the freedom to
                operate, including canceling, aborting or terminating current
                operation.
              </div>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
    </div>
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s" style="background: #65648B;">
      <div
          style="color: white; position: relative; left: 3vw; top: 2vw; z-index: 50; width: 20vw;font-weight: 500; font-size: 1.2vw">
        Roadmap
      </div>
      <div
          style="position: relative; left: 52px; top: 20vw; z-index: 50; color: white; width: 30vw;  ">
        <div style="font-size: 1.5vw">
          1. Mirai Community
        </div>
        <div>

        </div>
        <div style="font-size: 1vw; margin-top: .5vw; color: white; ">
          Launching our website, discord, twitter and Instagram page and building the Mirai Community.
        </div>
        <div style="border-bottom: 3px #ffffff solid; height: 5px; margin-top: 1vw; margin-bottom: .5vw;"></div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          2. $Mirai ecosystem
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          3. Club Mirai
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          4. Minting
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          5. Staking
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          6. Liar’s Dice
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          7. Mirai Governance
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          8. Renaissance
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
      </div>
      <img :src="racePermit" alt="" style="position: relative; left: 60vw; top: -15vw; z-index: 50; width: 30vw">
    </div>
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s" style="background: #859EA7;">
      <div
          style="color: white; position: relative; left: 3vw; top: 2vw; z-index: 50; width: 20vw;font-weight: 500; font-size: 1.2vw">
        Royalties
      </div>
      <div
          style="position: relative; left: 2vw; top: 50vh; z-index: 50; color: white; width: 30vw; transform: scale(.8,.8) ">
        <div style="font-size: 1.5vw">
          1. Project improvment
        </div>
        <div>

        </div>
        <div style="font-size: 1vw; margin-top: .5vw; color: white; ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut condimentum eros ac dolor pellentesque, mattis
          scelerisque erat volutpat. Nam imperdiet mauris quis neque sollicitudin ornare. Aenean ac nisi eget purus.
        </div>
        <div style="border-bottom: 3px #ffffff solid; height: 5px; margin-top: 1vw; margin-bottom: .5vw;"></div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          2. Liquidity pool
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>
        <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
          3. Core team
          <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
        </div>

      </div>

      <div style="color: white;font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 20vw;
line-height: 20vw;
width: 20vw;
position: relative;
top: -25vh;
left: 50vw;
">3.9%
      </div>
    </div>
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s"
         style="background: linear-gradient(47.3deg, #DCA9EB 0%, #A398F4 127.58%);">
      <div
          style="position: relative; left: 3vw; top: 2vw; color: white; z-index: 50; width: 20vw;font-weight: 500; font-size: 1.2vw">
        Team
      </div>
      <div
          style="position: relative; left: 2vw; top: 50vh; z-index: 50; color: white; width: 30vw; transform: scale(.8,.8) ">
        <div style="font-size: 1.5vw">
          1. The Chief
        </div>
        <div>

        </div>
        <div style="font-size: 1vw; margin-top: .5vw; color: white; ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut condimentum eros ac dolor pellentesque, mattis
          scelerisque erat volutpat. Nam imperdiet mauris quis neque sollicitudin ornare. Aenean ac nisi eget purus.
          <div style="border-bottom: 3px #ffffff solid; height: 5px; margin-top: 1vw; margin-bottom: .5vw;"></div>
          <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
            2. The Designer
            <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
          </div>
          <div style="font-size: 1.5vw;color: #FFFFFF; opacity: 0.12;">
            3. The Architect
            <div style="border-bottom: 3px #FFFFFF solid; height: 5px; margin-top: .5vw; margin-bottom: .5vw;"></div>
          </div>
        </div>

      </div>
      <img :src="racePermit" alt="" style="position: relative; left: 60vw; top: -5vw; z-index: 50; width: 30vw">
      <div>
        <el-collapse v-model="activeName" class="hidden" accordion>
          <el-collapse-item title="Consistency" name="1">
            <div>
              Consistent with real life: in line with the process and logic of real
              life, and comply with languages and habits that the users are used to;
            </div>
            <div>
              Consistent within interface: all elements should be consistent, such
              as: design style, icons and texts, position of elements, etc.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Feedback" name="2">
            <div>
              Operation feedback: enable the users to clearly perceive their
              operations by style updates and interactive effects;
            </div>
            <div>
              Visual feedback: reflect current state by updating or rearranging
              elements of the page.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Efficiency" name="3">
            <div>
              Simplify the process: keep operating process simple and intuitive;
            </div>
            <div>
              Definite and clear: enunciate your intentions clearly so that the
              users can quickly understand and make decisions;
            </div>
            <div>
              Easy to identify: the interface should be straightforward, which helps
              the users to identify and frees them from memorizing and recalling.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Controllability" name="4">
            <div>
              Decision making: giving advices about operations is acceptable, but do
              not make decisions for the users;
            </div>
            <div>
              Controlled consequences: users should be granted the freedom to
              operate, including canceling, aborting or terminating current
              operation.
            </div>
          </el-collapse-item>
        </el-collapse>

      </div>

    </div>
  </div>
  <!--  mobile view for stories-->
  <div class="md:hidden" style="">
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s"
         style="background: #F7F4EF; height: 115vh;">
      <div style="position: relative; left: 3vw; top: 10vw; z-index: 50; width: 40vw;font-weight: 500; font-size: 7vw">
        Utilities
      </div>
      <img :src="racePermit" alt="" style="position: relative; left: 15vw; top: 8vh; z-index: 50; width: 70vw">
      <div style="position: relative; left: 5vw; top: 15vh; z-index: 50; width: 90vw;">
        <div style="font-size: 6vw">
          1. Gaming
        </div>
        <div>

        </div>
        <div style="font-size: 14px; margin-top: 2.5vw;  ">
          Play, network, win. We will host regular tournaments to keep you engaged.
        </div>
        <div style="border-bottom: 1.5px black solid; height: 5px; margin-top: 1vw; margin-bottom: 2.5vw;"></div>
        <div style="font-size: 6vw;color: #292929; opacity: 0.12;">
          2. Real Life Collab
          <div style="border-bottom: 1.5px #292929 solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #292929; opacity: 0.12;">
          3. Staking
          <div style="border-bottom: 1.5px #292929 solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #292929; opacity: 0.12;">
          4. Voting Rights
          <div style="border-bottom: 1.5px #292929 solid; height: 5px; margin-top: 4.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #292929; opacity: 0.12;">
          5. NFT Upgrades
          <div style="border-bottom: 1.5px #292929 solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
      </div>
      <div>
        <el-collapse v-model="activeName" class="hidden" accordion>
          <el-collapse-item title="Consistency" name="1">
            <div>
              Consistent with real life: in line with the process and logic of real
              life, and comply with languages and habits that the users are used to;
            </div>
            <div>
              Consistent within interface: all elements should be consistent, such
              as: design style, icons and texts, position of elements, etc.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Feedback" name="2">
            <div>
              Operation feedback: enable the users to clearly perceive their
              operations by style updates and interactive effects;
            </div>
            <div>
              Visual feedback: reflect current state by updating or rearranging
              elements of the page.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Efficiency" name="3">
            <div>
              Simplify the process: keep operating process simple and intuitive;
            </div>
            <div>
              Definite and clear: enunciate your intentions clearly so that the
              users can quickly understand and make decisions;
            </div>
            <div>
              Easy to identify: the interface should be straightforward, which helps
              the users to identify and frees them from memorizing and recalling.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Controllability" name="4">
            <div>
              Decision making: giving advices about operations is acceptable, but do
              not make decisions for the users;
            </div>
            <div>
              Controlled consequences: users should be granted the freedom to
              operate, including canceling, aborting or terminating current
              operation.
            </div>
          </el-collapse-item>
        </el-collapse>

      </div>

    </div>
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s"
         style="background: #65648B;height: 140vh;">
      <div
          style="position: relative; color: white; left: 3vw; top: 10vw; z-index: 50; width: 40vw;font-weight: 500; font-size: 7vw">
        Roadmap
      </div>
      <img :src="racePermit" alt="" style="position: relative; left: 15vw; top: 8vh; z-index: 50; width: 70vw">
      <div style="position: relative; left: 5vw; top: 15vh; z-index: 50; width: 90vw; color: white;">
        <div style="font-size: 6vw">
          1. Mirai Community
        </div>
        <div>

        </div>
        <div style="font-size: 14px; margin-top: 2.5vw;  ">
          Launching our website, discord, twitter and Instagram page and building the Mirai Community.
        </div>
        <div style="border-bottom: 1.5px #ffffff solid; height: 5px; margin-top: 1vw; margin-bottom: 2.5vw;"></div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          2. $Mirai ecosystem
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          3. Club Mirai
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          4. Minting
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 4.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          5. Staking
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          6. Liar’s Dice
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          7. Mirai Governance
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          8. Renaissance
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
      </div>

    </div>
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s" style="background: #859EA7;height: 130vh;">
      <div
          style="position: relative; color: white; left: 3vw; top: 10vw; z-index: 50; width: 40vw;font-weight: 500; font-size: 7vw">
        Royalties
      </div>
      <div style="color: white;font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 35vw;
line-height: 35vw;
width: 96vw;
position: relative;
top: 12vh;
left: 2vw;
">3.9%
      </div>
      <div style="position: relative; left: 5vw; top: 38vh; z-index: 50; width: 90vw; color: white;">
        <div style="font-size: 6vw">
          1. Project improvment
        </div>
        <div>

        </div>
        <div style="font-size: 14px; margin-top: 2.5vw;  ">
          The goal of the royalties is to provide value to the holders, so the most significant part goes back into the project to further improve the Mirai.</div>
        <div style="border-bottom: 1.5px #ffffff solid; height: 5px; margin-top: 1vw; margin-bottom: 2.5vw;"></div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          2. Liquidity pool
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          3. Core team
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>

      </div>
    </div>
    <div class="single-page wow slideInUp" data-wow-duration="1s" data-wow-delay="0.25s"
         style="background: linear-gradient(47.3deg, #DCA9EB 0%, #A398F4 127.58%);height: 110vh;">
      <div
          style="position: relative; color: white; left: 3vw; top: 10vw; z-index: 50; width: 40vw;font-weight: 500; font-size: 7vw">
        Team
      </div>
      <img :src="racePermit" alt="" style="position: relative; left: 15vw; top: 8vh; z-index: 50; width: 70vw">
      <div style="position: relative; left: 5vw; top: 15vh; z-index: 50; width: 90vw; color: white;">
        <div style="font-size: 6vw">
          1. The Chief
        </div>
        <div>

        </div>
        <div style="font-size: 14px; margin-top: 2.5vw;  ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut condimentum eros ac dolor pellentesque, mattis
          scelerisque erat volutpat. Nam imperdiet mauris quis neque sollicitudin ornare. Aenean ac nisi eget purus.
        </div>
        <div style="border-bottom: 1.5px #ffffff solid; height: 5px; margin-top: 1vw; margin-bottom: 2.5vw;"></div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          2. The Designer
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
        <div style="font-size: 6vw;color: #FFFFFF; opacity: 0.12;">
          3. The Architect
          <div style="border-bottom: 1.5px #FFFFFF solid; height: 5px; margin-top: 2.5vw; margin-bottom: 2.5vw;"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue'
import mindmap from '@/assets/images/mindmap.png'
import HeroBanner from '@/assets/images/Hero Banner.png'
import space from '@/assets/images/space.jpg'
import bulb from '@/assets/icons/bulb.png'
import desert from '@/assets/images/desert.png'
import racePermit from '@/assets/images/racePermit 1.png'
import astronaut from '@/assets/images/astronaut.png'
import vegetable from '@/assets/images/vegetable.png'
import lettuce from '@/assets/images/lettuce.png'
import violetDesert from '@/assets/images/violetDesert.png'
import DaxGaming from '@/assets/images/DaxGaming.png'
import locomotive from '@/assets/css/locomotive-scroll.css'
import LocomotiveScroll from 'locomotive-scroll';
import WOW from "wow.js";
import {onMounted} from "vue";
import {transition} from "@headlessui/vue/dist/components/transitions/utils/transition";

const activeName = ref('1');
const scroll = new LocomotiveScroll();


export default {
  name: "Community",
  setup() {
    onMounted(() => {
      console.log('animation working')
      /*var wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        callback: function (box) {
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null, // optional scroll container selector, otherwise use window,
        resetAnimation: true, // reset animation on end (default is true)
      });
      wow.init();*/
    //  add listener for scrolling on the element with id utility to change the background smoothly

    })
    return {
      mindmap,
      space,
      HeroBanner,
      desert,
      racePermit,
      bulb,
      astronaut,
      vegetable,
      lettuce,
      violetDesert,
      DaxGaming,
    }

  },

  data() {
    return {
      redscar: require('@/assets/images/redscar.png'),
      activeName: ref('1')
    }
  },
  methods: {
    bgTransition() {
      // change the background of bgcontainer in 1 second
      console.log("trans the background of bgcontainer")
      let bgcontainer;
      bgcontainer = document.getElementById("utility")

    }
  }
}
</script>

<style scoped>
.not-pic {
  position: absolute;
  z-index: 200;
  width: 676px;
  height: 176px;
  left: calc(50% - 676px / 2 - 424px);
  top: 184px;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 88px;

  color: #FFFFFF;

}
#utility {
  transition: background-color 2s ease-out;
}
#utility:hover {
  background-color: green;
}
.unlockBanner {
  position: absolute;
  width: 676px;
  height: 96px;
  left: calc(50% - 676px / 2 - 424px);
  top: 392px;
  z-index: 200;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  /* or 150% */


  /* White */

  color: #FFFFFF;
}

.brand {
  /* 24:58:59 */
  position: relative;
  /*margin: 40vh auto;*/
  /* Heading / Regular */

  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 142px;
  /* or 101% */

  text-align: center;
  letter-spacing: -4.5px;

  /* Fill / Black */

  color: #191919;

  mix-blend-mode: normal;
  opacity: 0.9;
  /* identical to box height, or 38% */

  text-transform: capitalize;
  transform: translate(-50%, -50%);
}

.story {
  margin: 10vh auto;
  width: 90vw;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2.5vw;
  line-height: 2.5vw;
  /* or 133% */
  text-align: left;
}

.single-page {
  width: 1680px;
  height: 1080px;
}

.oval-orange {
  width: 32%;
  height: 100%;
  margin: auto;
  border-radius: 50%;
  border-color: #FF5C00;
  border-width: 2px;
  border-style: solid;
  /*transform: rotate(-10deg);*/
}

.bg-eco-1 {
  background: linear-gradient(47.3deg, #DB97AE 0%, #FFBF85 127.58%);
}
</style>
<style>
.el-collapse * {
  background: none;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Inter', SansSerif, serif;
  border: none;
  width: 40vw;
}
</style>

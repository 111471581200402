<template>
  <div class="bg-cover w-full">
<!--    <span class="text-white year hidden sm:inline">3939 CE</span>
    <div class="text-white absolute text-center font-extrabold text-9xl sm:hidden" style="top: 12vh; left: 4vw">
      3939
    </div>
    <div class="text-white absolute text-7xl sm:hidden" style="font-weight: 1000; top: 28vh; left: 5vw">
      CE
    </div>
    &lt;!&ndash; show 4 pictures in a row using v-for &ndash;&gt;
    <div class="fourGuys hidden sm:inline">
      <img :src="avatar1" alt="">
    </div>-->
    <img :src="heroDesktop" class="sm:block hidden" alt="">
    <img :src="heroDesktopMobile" class="sm:hidden" alt="">

    <div style="position: relative; z-index: 10; bottom: 0; width: 100%; align-self: flex-end" class="sm:hidden">
      <el-image style="" :src="mobilebanner" fit="cover"/>
    </div>
  </div>
  <div class=" bg-black" style=" min-width: 100vw">
    <div class="flex mx-auto text-center md:block hidden">
      <p class="content-center story text-white whitespace-pre-line">Four friends, and the founding members of Delta
        decide to go to Club
        Mirai and enter
        the competition in the hopes of winning themselves coveted spots on the Ark. Yet, so does everyone else.</p>
    </div>
    <div class="flex mx-auto text-center md:hidden">
      <p class="content-center story_m text-white whitespace-pre-line">Four friends, and the founding members of Delta
        decide to go to Club
        Mirai and enter
        the competition in the hopes of winning themselves coveted spots on the Ark. Yet, so does everyone else.</p>
    </div>
    <transition name="fade">
<!--
      <div id="chapter-before" v-if="ischapt"
           class="flex items-center justify-around flex-row mx-8 mt-20 hidden sm:flex ">
        &lt;!&ndash;        <el-scrollbar >&ndash;&gt;
        <div class="chapters mx-1.5 flex" v-for="index in 1">
          <div class="self-start" style="margin-top: 2vh; margin-left: 1.5vw; height: 30%">
            <div v-if="index <= 1" class="chapter-item" @click="ischapt = false">Story</div>
            <div class="read-more" v-if="index <= 1" @click="ischapt = false">Read more →</div>
          </div>
        </div>
      </div>
-->

      <div class="hidden sm:flex justify-evenly">
        <div class="story-pc" style="clear: both; margin-left: 0vw">
          <h3 style="color: white;width: 576px; font-size: 40px; float: left; margin-right: 124px;">
            Chapter 1
            <div class="gradient-filler" style="width: 576px; margin-top: 2vh;float: left" ></div>
          </h3>
          <div style="float: right;">
          <div class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: 6vh; ">
            The sound of metal striking metal filled the air as Dax struck blow after blow against his sparring
            opponent.
            The endless amber haze that hung over the city of Romulus reflected off the blades, giving them a golden
            glow.
            <br>
            <br>
            “Enough, enough!” Elder Remus said.
            <br>
            <br>

            Dax grinned as his opponent relented, holding his free hand up in surrender. “Come on, I was just getting
            warmed
            up!” Sheathing his sword, he pushed his goggles back up onto his forehead.
            <br>
            <br>

            “Regardless, I am done. Are you coming back in?” the elder gestured to the stone walls that wrapped around
            their
            city.
            <br>
            <br>

            “I think I might stay out here for awhile longer.” Dax replied, “Maybe practise some more moves.”
            <br>
            <br>

            “You mean smoke one of those cigar’s you know you’re not supposed to have possession of.” Remus replied.
            <br>
            <br>

            “I have no idea what you’re talking about.” Dax said, his expression blank.
            <br>
            <br>

            Remus nodded and turned his back, “Not too long.”
            <br>
            <br>

            Dax waited until Remus was near the city walls before he withdrew the hand rolled cigar from his jacket
            pocket
            and lit it, taking quick puffs as he waited for the flame to take. He held the cigar between his teeth as he
            exhaled and stretched out his arms. His skin was fair, wrapped in red streaks. All the people of Romulus had
            them—though they started off as faint as their skin, only growing darker and changing colour as the
            individual
            gained knowledge and experience. Dax was the youngest Romulan to ever achieve the colour of red in the
            history
            of his people. Some elders never attained it.
            <br>
            <br>

            There was something about Dax that made him stand out, even among his people—and it wasn’t his dishevelled,
            ruby-red hair. His intelligence and focus were unrivalled, only fuelling the myths surrounding Romulus; that
            their inhabitants descended from ancient aliens that returned after the fallout of the final war. Though,
            like
            all the remaining inhabitants of Earth, none of them were whole.
            <br>
            <br>

            At least, not in the sense that mankind was before the war. Robotics advancements had allowed them to
            strengthen
            themselves where radiation weakened them, and everyone was made up at least partly of this tech. Dax himself
            was
            70% cyborg which was low compared to people from other cities—which again fed into the rumours of their
            origin.
            <br>
            <br>
            Taking long strides across the dry, yellow grass, Dax puffed on his cigar as he made his way home. Yet he’d
            only
            taken a few steps when he felt the tremor in his wrist which meant he’d received a message. Holding up his
            forearm he saw it was an announcement from NASA and he stopped in his tracks to read it.
          </div>
          <div class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-bottom: 2vh">
            Attention all citizens!
            On Saturn Day, the 13th day of Maeve, Club Mirai is hosting a two-day tournament across all gaming rooms.
            This once in a life-year event will award four winners with the ultimate prize.
            A place on the Ark.
            Click on the link to register yourself and/or your team now.
            Places are limited.
          </div>
          <div class="text-white whitespace-pre-line paragraph"
               style="width: 576px;">
            The cigar fell from his mouth and Dax had to quickly bend over and scoop it up before he accidently set the
            entire field alight. Putting his forefinger to his temple, Dax sent a call out to his best friend, Cell.
          </div>
          </div>
          <div style="clear: both;"></div>
          <h3 style="color: white;width: 576px; font-size: 40px; float: left; margin-right: 124px;">
            Chapter 2
            <div class="gradient-filler" style="width: 576px; margin-top: 2vh;float: left" ></div>
          </h3>
          <div style="float: right;">
          <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: 6vh; ">
            “Yo!” Cell greeted Dax as he heard his voice. His generation had developed a hive-mind ability to communicate with one another in a telepathic nature.
            <br>
            <br>

            “Did you see the announcement?” Dax asked.
            <br>
            <br>

            “I felt the tremor of the alert, but we’re in the middle of a harvest so I haven’t had time to read it yet.” Cell confessed.
            <br>
            <br>

            “Read now. Seriously, dude, then get back to me. I’ll check in with the others while I wait.” Dax announced.
            <br>
            <br>

            “Uh, okay?” Cell replied, but Dax was already gone. Cell quickly checked his message, his eyes widening as he read. It was his dream to live up on the Ark, to be a part of the advancement of their consciousness as mankind. He wanted to take the skills he’d learned farming the Nuke Plant—and helping convert it into their primary power source—and use them on the Ark. One day, Cell hoped to use these schools on a new planet. They just had to find a habitable one first. He looked wistfully towards the horizon of The Midlands, where he could make out NASA’s launching towers. It had been at least a year since the last voyage to the Ark. It seemed a strange contrast; fields of endless purple flowers as far as the eye could see, met with the harsh steel of the launching towers.
            <br>
            <br>

            Maybe the rumours are true, he thought, four vacancies means four deaths on the Ark, and no one is really buying the illness story. Not with only four fatalities.
            <br>
            <br>

            Cell’s purple hair crackled with static electricity, and he ran his hands through it to calm it down. It always happened when there was an increase of electricity in the air—which usually meant a storm was coming. The Midlands had the worst storms on the planet, and Cell had always wondered if it had something to do with the abundance of Nuke Plants in the area. They were certainly potent, long since turning The Midland inhabitants skin a purple hue and creating a gel-like goop they secreted like sweat. Long before he was born, the Wisterian’s—the people of The Midlands, had discovered a way to stop the highly irradiated secretions from damaging the robotic parts of their body. They created a power core which nestled into the chest piece that absorbed the goop and converted it into energy—much like how they converted power from the Nuke plants. The extra boost of power manifested in his people in different ways, depending on how much robotics their bodies relied upon. For Cell, it was speed, and he’d yet to meet anyone that could outrun him. Which was lucky considering his cheeky nature and propensity for pranks and risks meant he often had to leave in a hurry.
            <br>
            <br>

            If it wasn’t for his friends, he’d high tail it the whole way to Club Mirai right now. Instead, he put his forefinger to his temple and told Dax he was on his way.
            <br>
            <br>

            This is going to be bloody brilliant.
            <br>
            <br>

            Then Cell ran.
          </div>
          </div>
          <div style="clear: both;"></div>
          <h3 style="color: white;width: 576px; font-size: 40px; float: left; margin-right: 124px;">
            Chapter 3
            <div class="gradient-filler" style="width: 576px; margin-top: 2vh;float: left" ></div>
          </h3>
          <div style="float: right;">
          <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: 6vh; ">
            Aero gazed out the enormous arched window, absently admiring the steep, ice-capped York mountains beyond, where the homes of the people nestled within their ridges. An ancient tome rested open in her hands.
            <br>
            <br>

            “That’s a funny way of reading.” A dry voice came from behind her, and Aero looked over her shoulder, blushing slightly as her mentor stared sternly from the doorway.
            <br>
            <br>

            “I’m sorry.” Aero hastily returned to the desk, placing the book to the side as she poised her fingers over the screen, ready to transcribe. Sneaking a look from beneath her long lashes, she saw her mentor give a pleased nod before he turned and left the room, his robes dragging along the tiles the only sound. Aero sighed once he was no longer in sight and looked over her shoulder back at the window. Her people her the historians of the new world; their duty was to ensure the past never repeated itself, that this time they would learn from the lessons of the past and move towards a higher consciousness and away from the war and hatred of their ancestors.
            <br>
            <br>

            As she was not yet of age, Aero had the boring task set for all the youth of York—the manual transcription of the world’s oldest texts into digital format. It wasn’t the text itself that she found boring—Aero quite enjoyed history, but she was young and loved being outside, not pent up in the lofty halls of the archives. Unable to help herself, she got up once more and went to the window, feeling a twitch in the steel wings which hung down her back, itching for the opportunity to expand and send her soaring along the closest air current. Like most of the inhabitants of York, she was 90% cyborg, mostly on account of the wings that made it easier to travel along the steep and treacherous mountain range they called their home. Her skin was as fair as the snow beyond the window and her eyes were the colour of ice. A gust of wind rushed past, whipping her long blonde hair around her shoulders as she heard the voice of Dax in her mind.
            <br>
            <br>

            “I hate to pull you away from your books…” he said in greeting.
            <br>
            <br>

            “Please do.” Aero replied softly, glancing over her shoulder to make sure her mentor was not lurking again.
            <br>
            <br>

            “I take it you haven’t seen the alert either?”
            <br>
            <br>

            “You know I’m not aloud to receive messages when I’m in the archives.” Aero replied.
            <br>
            <br>

            “You’re not allowed to communicate with your friends either but look at you go!”
            <br>
            <br>

            “Shut up,” Aero smiled despite herself, “Tell me about this alert since it’s so important.”
            <br>
            <br>

            “Short version, there are free spaces on the Ark and Club Mirai are hosting a competition…”
            <br>
            <br>

            “…and the winners get to go to the Ark!” Aero exclaimed before clamping her hand over her mouth while the echoes of her voice taunted her from all corners of the archives.
            <br>
            <br>

            “Way to steal my thunder, Aero.” Dax replied dryly.
            <br>
            <br>

            “I’m sorry. But that’s super exciting! I take it you’re going?” she said.
            <br>
            <br>

            “Correction. We’re going. You’re one of the founding members of The Delta Aero! What kind of gang representation would we have with only three of the members?”
            <br>
            <br>

            “So Cell and Grim are coming too?” Aero asked.
            <br>
            <br>

            “For a chance to live on the Ark? To work towards expanding the consciousness of all mankind? Of course they are! I mean, I haven’t told Grim yet, but hell yeah, we’re all going.” Dax’s enthusiasm was catching, and Aero was smiling so widely her cheeks were beginning to ache.
            <br>
            <br>

            “You’re the only one with any real issue with getting away…” Dax said, “You know, with that Yorkist sense of responsibility that you have.”
            <br>
            <br>

            Aero rolled her eyes. “I take it we’re meeting at Grim’s?”
            <br>
            <br>

            “You bet. So you’re in?” Dax asked.
            <br>
            <br>

            “As if the three of you have any hope in hell without me.” Aero replied, tapping her temple with her finger as she signed off. She hesitated for just a moment as she took a final look over shoulder to where the tome remained open at the same page, untouched.
            <br>
            <br>

            I’ll be in so much trouble once my mentor returns, but what the hell, it’s The Ark!
            <br>
            <br>

            Aero stepped up onto the window ledge and held her arms out. Raising her head to the clouds she smiled as the wind hurtled towards her with encouragement. Grinning, Aero listened for the slight mechanical whirring as her wings unfurled as and she felt them catch the wind, she leapt off the ledge and soared towards the valley that would lead her from the York Mountains to her friends.
            <br>
            <br>


          </div>
          </div>
          <div style="clear: both;"></div>
          <h3 style="color: white;width: 576px; font-size: 40px; float: left; margin-right: 124px;">
            Chapter 4
            <div class="gradient-filler" style="width: 576px; margin-top: 2vh;float: left" ></div>
          </h3>
          <div style="float: right;">
          <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: 5vh; ">
            Grim spun the staff over his head three times, gaining momentum before he swung it downwards, crouching as he drew it sideways and knocked his opponent off his feet. He spun with the staff, his feet barely touching the concrete floor as he stopped abruptly, the end of the shaft pressed against his opponent’s neck. With a nod, Grim confirmed his win and dismissed his sparring opponent, who scurried to their feet and dashed from the room as though it were on fire.
            <br>
            <br>

            “If only you moved that quickly when you fought!” Grim called out after him, smirking to himself as he walked over the basin against the far wall and splashed cool water over his face. Looking up at the tarnished mirror hanging over it, Grim admired the way his eyes flashed red, and his long black hair stuck out in all directions. His eyes only did that when he was angry or worked up, and he grinned, acknowledging that he’d given himself a sufficient workout. A master combat fighter, he had a reputation to protect, and in a place like Lincoln, reputations could be made and lost again all in a day.
            <br>
            <br>

            Lincoln was the most populated city in all of Mirai, thankfully, in part, to the infamous Club Mirai. Its urban streets were a labyrinth of ruined buildings of the old world, patched up and modified with whatever the locals could get their hands on. The sunshine never reached Lincoln, instead it was the city of eternal night—yet it was never dark. The streets were loaded with neon signs and flashing lights, as everyone tried to cash in on the popularity of the club, offering everything from food to a bed, to licentious entertainments and more. There was something for everyone in Lincoln. If you could stand the crowds.
            <br>
            <br>

            And the gangs.
            <br>
            <br>

            Grim loved everything about Lincoln. At 95% cyborg, and a street fighter, there was no where else in the new world that suited him better. Everyone knew him as strong, fierce, short-tempered, unpredictable and a little violent, which often led less than intelligent folk to question why he wasn’t the leader of The Delta Gang. After he punched them in the face, he’d explain that above all else, he was loyal.
            <br>
            <br>

            Loyal to his gang.
            <br>
            <br>

            Loyal to its members.
            <br>
            <br>

            Loyal to its other founders.
            <br>
            <br>

            Loyal to Dax, their leader—and his best friend.
            <br>
            <br>

            “Saving the best for last?” Grim said as he tapped his temple.
            <br>
            <br>

            “Always.” Dax laughed, “Besides I figured you’d know all about it anyway.”
            <br>
            <br>

            “If you’re talking about the competition at Club Mirai, it’s all anyone is talking about. The streets are growing busier by the hour, if you can believe it.” Grim flopped down on the futon that doubled as both a lounge and his bed and rested his bare feet up on the side table. “I reckon entire population of Mirai is headed for Lincoln.”
            <br>
            <br>

            “Close to it, I’d say.” Dax agreed. “How often do we get the chance to win a place on the Ark?”
            <br>
            <br>

            “There’s going to be fight’s breaking out all over the place.” Grim declared.
            <br>
            <br>

            “You sound more excited about street brawls than the competition itself.” Dax said.
            <br>
            <br>

            Grim shrugged, “Let’s just say I think I have more chance of winning a street fight than a place on the Ark.”
            <br>
            <br>

            “No negative thinking, bro!” Dax chastised him, “There are four places up for grabs. That’s you, me, Cell and Aero.”
            <br>
            <br>

            “You sound confident.”
            <br>
            <br>

            “When aren’t I? I need to be confident for the both of us.” Dax laughed again.
            <br>
            <br>

            “And what happens to The Delta Gang if all its founding members head up into space?”
            <br>
            <br>

            “Then we’ll promote the next round of leaders. Gods know there are plenty to choose from, and all of which would be honoured to take the lead. But let’s cross that bridge when we get to it.” Dax declared.
            <br>
            <br>

            “If.”
            <br>
            <br>

            “Huh?”
            <br>
            <br>

            “You said, when,” Grim said, “and I corrected you to if.”
            <br>
            <br>

            “Whatever, bro. Prepare yourself for company. The three of us will join you as soon as we can.” Dax signed off.
            <br>
            <br>

            Grim got to his feet and picked up the strips of material from the side table and started wrapping his hands as he strode over to the punching bag. All the excited made him want to punch something.
            <br>
            <br>


          </div>
          </div>
          <div style="clear: both;"></div>
          <h3 style="color: white;width: 576px; font-size: 40px; float: left; margin-right: 124px;">
            Chapter 5
            <div class="gradient-filler" style="width: 576px; margin-top: 2vh;float: left" ></div>
          </h3>
          <div style="float: right;">
          <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: 5vh; ">
            Commander Culver pulled her helmet over her head as the air lock closed behind her.
            <br>
            <br>

            “Just for the record, I don’t like this one little bit.” Commander Denver said through the speaker on the other side of the door.
            <br>
            <br>

            “What’s to like? We’re going in blind here.” She replied, locking the helmet into place.
            <br>
            <br>

            “That too, but I was talking about the fact you’re in there and I’m out here.” Denver said.
            <br>
            <br>

            Culver turned and placed her hands on her hips as she glared at him through the small window in the door. “You know how much I hate that macho bullshit, Richard. Just because I’m a woman, doesn’t make me any less competent…”
            <br>
            <br>

            “That’s not what I meant, Mercedes, bloody hell…” he ran his hand through his hair, “Just don’t take any unnecessary risks, okay? I’ll be watching via your cam but at the first sign of anything off, you get back onto this ship, you hear me? The General can get stuffed.” Denver said.
            <br>
            <br>

            Culver rolled her eyes but couldn’t help but smile, “I’d love to be a fly on the wall for that conversation.”
            <br>
            <br>

            “There’s no flies in space, Commander.” Denver smiled back.
            <br>
            <br>

            “Okay, let’s get this over with.” Commander Culver said as she turned her back and moved towards the door lock on the other side of the room. She took a deep breath as she placed her hands on it and carefully opened it, revealing the connecting tunnel that would lead her to where the Aspiration IV was now docked, silent and waiting. They’d been able to take control of the ship to allow them to manually dock it but had been unable to obtain any further information about who—or what—might be awaiting them. They had no idea what had happened to the Aspiration IV, but their communication systems were dead.
            <br>
            <br>

            “You okay, Culver?”
            <br>
            <br>

            “Sure am, Denver. Moving in now, a couple more steps…okay I’ve reached the hatch and I’m opening it.” Culver said, wondering if he could hear her heart thundering against her chest through her mike. There was a slight hissing sound as the hatch opened and darkness greeted her.
            “Did I tell you already how much I don’t like this?” Denver said softly.
            <br>
            <br>

            “Shut up, Denver.” She replied just as quietly as she stepped over the threshold and into the ship. Activating the light on her helmet did little to pierce through the darkness, but it at least allowed her to make sure one foot went in front of the other. She’d never admit it to Commander Denver, but she had sudden flashes of stumbling in the dark over the bodies of dead crew members, and the thought sent a chill down her spine.
            <br>
            <br>

            “I’m entering the main hull of the ship.” She announced quietly, finding some semblance of reassurance that Denver was at the other end, even though she knew it wouldn’t help her if she ran into any real trouble. Slowly, she scanned the room, trying to see as much as she could with the little light she had. Culver had a torch in her pack, but she didn’t want to use it until she knew what they were dealing with—just in case it drew to her any unwanted attention.
            <br>
            <br>

            “Culver?” Denver’s voice spoke into her ear.
            <br>
            <br>

            “All fine here. Nothing seems out of the ordinary so far.” She replied.
            <br>
            <br>

            “You mean aside from the fact the crew appears to have disappeared.” Denver said dryly.
            <br>
            <br>

            “Yeah, aside from that.” Culver stated, as much to herself as to him. The room opened out into three directions, and thanks to NASA, she’d thoroughly memorised the lay out. The branch off to the right would lead to the bridge, the middle branch would lead through the general communal area before branching upwards towards the green station, and downwards for the mechanical station. The far side of the communal area then branched out into the medical and science stations. The last branch would leave her towards the berths, including the captain’s private rooms and the cyrosleep room. First things first, she knew she had to check the bridge.
            <br>
            <br>

            The Commander stepped into the room and gazed at the glass window rising before the bridge. The room was empty.
            <br>
            <br>

            “There’s no one in here. It’s eerie seeing the bridge empty.” She whispered to Denver.
            <br>
            <br>

            “I bet. Leave it for now, you can check over the systems once we make sure there’s no one lying in wait for you.” Denver said in an attempt at humour which only put Carver further on edge. He fell silent as she left the bridge to continue her investigation.
            <br>
            <br>

            “Conducting thermal scan.” Culver said as she moved as soundlessly as she could across the floor. She figured her best chance to get a good read would be from the centre of the communal section—and centre—of the ship. “Nothing detected so far.”
            <br>
            <br>

            “Copy that.”
            <br>
            <br>

            Culver tried to focus on keeping her breathing steady as she made her way along, trying to keep a keen eye on the thermal as well as on her own feet. Finally, she was in place, and she turned slowly to her right, moving the camera along with her as she watched intently.
            <br>
            <br>

            “Anything?” Denver asked.
            <br>
            <br>

            “Nothing yet.” Culver confirmed. She’d almost come full circle when the thermal picked up a heat signature from up ahead. “I think I have something.”
            <br>
            <br>

            “What? Where?” Denver asked.
            <br>
            <br>

            “It looks like it’s from the medical station. I’m going to take a look.” She confirmed.
            <br>
            <br>

            “Be careful.” He warned.
            <br>
            <br>

            “I always am.” It was all Culver could do to keep her pace steady, and it felt like to took minutes, not seconds, to reach the hatch to the medical station.
            <br>
            <br>

            “Hatch is open.” She announced.
            <br>
            <br>

            “Okay. Not unusual. Has the heat signature moved?”
            <br>
            <br>

            “Not so far.” Culver didn’t dare to speak any louder than a whisper, but I can see light. It’s dim, but there’s light.”
            <br>
            <br>

            “Steady, Culver.” Commander Denver said.
            <br>
            <br>

            Culver tucked the thermal away, and drew her weapon—moving or not, she didn’t want any surprises. Walking into the medical station, she looked around the main lab, which was perfectly intact, albeit empty. The light was coming from another room branching off from the far right. She weaved her way across the lab towards the golden glow with equal parts terror and excitement. Pausing at the entrance, she saw it was a ward lined with beds, all of which were empty.
            <br>
            <br>

            Except for one.
            <br>
            <br>

            “There’s someone in the medical ward.” Culver announced as she walked cautiously towards the bed and gasped as she saw who it was. “Denver, it’s Captain Richardson!”
            <br>
            <br>

            “He’s alive! Is he conscious? What are his vitals?” Denver asked in a rush.
            <br>
            <br>

            “Give me a second.” Culver snapped as she ran her gaze over the Commander and the machine he was connected to. It didn’t take her long to realise the Captain of the Aspiration IV was in a coma.
            <br>
            <br>

            “What the hell happened to you, Captain?” Culver murmured, “And where is the rest of your crew?”

          </div>
          </div>
          <div style="clear: both;"></div>
          <h3 style="color: white;width: 576px; font-size: 40px; float: left; margin-right: 124px;">
            Chapter 6
            <div class="gradient-filler" style="width: 576px; margin-top: 2vh;float: left" ></div>
          </h3>
          <div style="float: right; margin-bottom: 15vh;">
          <div  class="text-white whitespace-pre-line paragraph " style="width: 576px; margin-top: 5vh; ">
            “Grim! You always look so happy to see me!” Dax declared as he arrived at his friend’s apartment.
            <br>
            <br>

            “Where are the others?” Grim asked in greeting.
            <br>
            <br>

            “You two looking for me?” Cell said as he stepped into the room, only slightly sweating from his endurance run.
            <br>
            <br>

            “Of course Aero is the last to arrive.” Grim growled.
            <br>
            <br>

            “It’s a woman’s prerogative to make an entrance.” Aero declared as she hovered down towards the entrance, her feet delicately touching the ground as her wings folded in on themselves.
            <br>
            <br>

            “The Delta founders are in the house!” Whooped Cell.
            <br>
            <br>

            “And keen to kick some gaming butt.” Dax high-fived him.
            <br>
            <br>

            “I sent the word out on the street,” Grim confirmed, “the other gang members will join us as we make our way to the club.”
            <br>
            <br>

            “Win or not, this is going to be epic.” Aero declared as she turned on her heel and led them out onto the street which was already bustling with crowds.
            <br>
            <br>

            “Man, I don’t know how you deal with all these bright lights all the time.” Cell said, raising his arm up dramatically over his eyes as though trying to block out the sun.
            <br>
            <br>

            “Maybe if you spent less time out in your field of flowers, you’d have more appreciation for the city life.” Grim retorted.
            <br>
            <br>

            “You’re forgetting it’s those flowers that allow you drown yourself in neon light day in, day out.” Cell grinned. Grim just shrugged and turned to Dax.
            <br>
            <br>

            “After you.”
            <br>
            <br>

            Dax put two fingers to his forehead in a salute as he turned and led the way. He couldn’t wipe the smile from his face, and as he turned and looked at his friends, he saw they felt the same—except for Grim.
            <br>
            <br>

            “I’m smiling on the inside.” Grim said, and the others laughed.
            <br>
            <br>

            The energy in the streets was palpable, as though the city itself was alive, the streets were its veins, and the people were the lifeforce streaming towards the city’s heart—Club Mirai. Despite the congregating gangs gathering, there were no signs of aggression—everyone was too excited. Their own gang grew exponentially as Delta member fell in line alongside them. Even if someone didn’t know the way to the club, all they would have to do was join the sea of bodies sweeping towards it.
            <br>
            <br>

            Within half an hour, the four friends stood side by side, gazing up at the megalithic structure of shipping containers that was Club Mirai.
            <br>
            <br>

            “Say hello to our futures, gang!” Dax declared, and together, they approached the club.
            <br>
            <br>

            And their destinies.
            <br>
            <br>


          </div>
          </div>
        </div>
        <div style="clear: both; "></div>
      </div>
    </transition>
    <transition name="fade" class="md:hidden">


      <div class="" style="max-width: 90vw">
<!--        <div style="display: block; margin: auto 10vw;">
          <div class="chapter-row-highlighted-m-row">
            <div class="chapter-word-row" @click="ischapt = true">Story</div>
          </div>
&lt;!&ndash;          <div class="chapter-row-highlighted-m-row" v-for="(item, key) in 5">&ndash;&gt;
&lt;!&ndash;            <div class="chapter-word-row" @click="ischapt = true">Chapter {{key + 2}}</div>&ndash;&gt;
&lt;!&ndash;          </div>&ndash;&gt;
        </div>-->

        <div class="story-mobile" style="margin: 8vw">
          <h3 style="color: white; font-size: 40px; margin-top: 3vh; width: 84vw; text-align: left;">
            Chapter 1
            <div class="gradient-filler" style="width: 84vw; margin-top: 2vh;" ></div>
          </h3>
          <div class="text-white whitespace-pre-line paragraph-m " style="width: 84vw; margin-top: 5vh; ">
            The sound of metal striking metal filled the air as Dax struck blow after blow against his sparring
            opponent.
            The endless amber haze that hung over the city of Romulus reflected off the blades, giving them a golden
            glow.
            <br>
            <br>
            “Enough, enough!” Elder Remus said.
            <br>
            <br>

            Dax grinned as his opponent relented, holding his free hand up in surrender. “Come on, I was just getting
            warmed
            up!” Sheathing his sword, he pushed his goggles back up onto his forehead.
            <br>
            <br>

            “Regardless, I am done. Are you coming back in?” the elder gestured to the stone walls that wrapped around
            their
            city.
            <br>
            <br>

            “I think I might stay out here for awhile longer.” Dax replied, “Maybe practise some more moves.”
            <br>
            <br>

            “You mean smoke one of those cigar’s you know you’re not supposed to have possession of.” Remus replied.
            <br>
            <br>

            “I have no idea what you’re talking about.” Dax said, his expression blank.
            <br>
            <br>

            Remus nodded and turned his back, “Not too long.”
            <br>
            <br>

            Dax waited until Remus was near the city walls before he withdrew the hand rolled cigar from his jacket
            pocket
            and lit it, taking quick puffs as he waited for the flame to take. He held the cigar between his teeth as he
            exhaled and stretched out his arms. His skin was fair, wrapped in red streaks. All the people of Romulus had
            them—though they started off as faint as their skin, only growing darker and changing colour as the
            individual
            gained knowledge and experience. Dax was the youngest Romulan to ever achieve the colour of red in the
            history
            of his people. Some elders never attained it.
            <br>
            <br>

            There was something about Dax that made him stand out, even among his people—and it wasn’t his dishevelled,
            ruby-red hair. His intelligence and focus were unrivalled, only fuelling the myths surrounding Romulus; that
            their inhabitants descended from ancient aliens that returned after the fallout of the final war. Though,
            like
            all the remaining inhabitants of Earth, none of them were whole.
            <br>
            <br>

            At least, not in the sense that mankind was before the war. Robotics advancements had allowed them to
            strengthen
            themselves where radiation weakened them, and everyone was made up at least partly of this tech. Dax himself
            was
            70% cyborg which was low compared to people from other cities—which again fed into the rumours of their
            origin.
            <br>
            <br>
            Taking long strides across the dry, yellow grass, Dax puffed on his cigar as he made his way home. Yet he’d
            only
            taken a few steps when he felt the tremor in his wrist which meant he’d received a message. Holding up his
            forearm he saw it was an announcement from NASA and he stopped in his tracks to read it.
            <br>
            <br>
            Attention all citizens!
            On Saturn Day, the 13th day of Maeve, Club Mirai is hosting a two-day tournament across all gaming rooms.
            This once in a life-year event will award four winners with the ultimate prize.
            A place on the Ark.
            Click on the link to register yourself and/or your team now.
            Places are limited.
            <br>
            <br>
            The cigar fell from his mouth and Dax had to quickly bend over and scoop it up before he accidently set the
            entire field alight. Putting his forefinger to his temple, Dax sent a call out to his best friend, Cell.

          </div>

          <h3 style="color: white; font-size: 40px; margin-top: 3vh; width: 84vw; text-align: left;">
            Chapter 2
            <div class="gradient-filler" style="width: 84vw; margin-top: 2vh;" ></div>
          </h3>

          <div  class="text-white whitespace-pre-line paragraph-m " style="width: 84vw; margin-top: 5vh; ">
            “Yo!” Cell greeted Dax as he heard his voice. His generation had developed a hive-mind ability to communicate with one another in a telepathic nature.
            <br>
            <br>

            “Did you see the announcement?” Dax asked.
            <br>
            <br>

            “I felt the tremor of the alert, but we’re in the middle of a harvest so I haven’t had time to read it yet.” Cell confessed.
            <br>
            <br>

            “Read now. Seriously, dude, then get back to me. I’ll check in with the others while I wait.” Dax announced.
            <br>
            <br>

            “Uh, okay?” Cell replied, but Dax was already gone. Cell quickly checked his message, his eyes widening as he read. It was his dream to live up on the Ark, to be a part of the advancement of their consciousness as mankind. He wanted to take the skills he’d learned farming the Nuke Plant—and helping convert it into their primary power source—and use them on the Ark. One day, Cell hoped to use these schools on a new planet. They just had to find a habitable one first. He looked wistfully towards the horizon of The Midlands, where he could make out NASA’s launching towers. It had been at least a year since the last voyage to the Ark. It seemed a strange contrast; fields of endless purple flowers as far as the eye could see, met with the harsh steel of the launching towers.
            <br>
            <br>

            Maybe the rumours are true, he thought, four vacancies means four deaths on the Ark, and no one is really buying the illness story. Not with only four fatalities.
            <br>
            <br>

            Cell’s purple hair crackled with static electricity, and he ran his hands through it to calm it down. It always happened when there was an increase of electricity in the air—which usually meant a storm was coming. The Midlands had the worst storms on the planet, and Cell had always wondered if it had something to do with the abundance of Nuke Plants in the area. They were certainly potent, long since turning The Midland inhabitants skin a purple hue and creating a gel-like goop they secreted like sweat. Long before he was born, the Wisterian’s—the people of The Midlands, had discovered a way to stop the highly irradiated secretions from damaging the robotic parts of their body. They created a power core which nestled into the chest piece that absorbed the goop and converted it into energy—much like how they converted power from the Nuke plants. The extra boost of power manifested in his people in different ways, depending on how much robotics their bodies relied upon. For Cell, it was speed, and he’d yet to meet anyone that could outrun him. Which was lucky considering his cheeky nature and propensity for pranks and risks meant he often had to leave in a hurry.
            <br>
            <br>

            If it wasn’t for his friends, he’d high tail it the whole way to Club Mirai right now. Instead, he put his forefinger to his temple and told Dax he was on his way.
            <br>
            <br>

            This is going to be bloody brilliant.
            <br>
            <br>

            Then Cell ran.
          </div>
          <h3 style="color: white; font-size: 40px; margin-top: 3vh; width: 84vw; text-align: left;">
            Chapter 3
            <div class="gradient-filler" style="width: 84vw; margin-top: 2vh;" ></div>
          </h3>

          <div  class="text-white whitespace-pre-line paragraph-m " style="width: 84vw; margin-top: 5vh; ">
            Aero gazed out the enormous arched window, absently admiring the steep, ice-capped York mountains beyond, where the homes of the people nestled within their ridges. An ancient tome rested open in her hands.
            <br>
            <br>

            “That’s a funny way of reading.” A dry voice came from behind her, and Aero looked over her shoulder, blushing slightly as her mentor stared sternly from the doorway.
            <br>
            <br>

            “I’m sorry.” Aero hastily returned to the desk, placing the book to the side as she poised her fingers over the screen, ready to transcribe. Sneaking a look from beneath her long lashes, she saw her mentor give a pleased nod before he turned and left the room, his robes dragging along the tiles the only sound. Aero sighed once he was no longer in sight and looked over her shoulder back at the window. Her people her the historians of the new world; their duty was to ensure the past never repeated itself, that this time they would learn from the lessons of the past and move towards a higher consciousness and away from the war and hatred of their ancestors.
            <br>
            <br>

            As she was not yet of age, Aero had the boring task set for all the youth of York—the manual transcription of the world’s oldest texts into digital format. It wasn’t the text itself that she found boring—Aero quite enjoyed history, but she was young and loved being outside, not pent up in the lofty halls of the archives. Unable to help herself, she got up once more and went to the window, feeling a twitch in the steel wings which hung down her back, itching for the opportunity to expand and send her soaring along the closest air current. Like most of the inhabitants of York, she was 90% cyborg, mostly on account of the wings that made it easier to travel along the steep and treacherous mountain range they called their home. Her skin was as fair as the snow beyond the window and her eyes were the colour of ice. A gust of wind rushed past, whipping her long blonde hair around her shoulders as she heard the voice of Dax in her mind.
            <br>
            <br>

            “I hate to pull you away from your books…” he said in greeting.
            <br>
            <br>

            “Please do.” Aero replied softly, glancing over her shoulder to make sure her mentor was not lurking again.
            <br>
            <br>

            “I take it you haven’t seen the alert either?”
            <br>
            <br>

            “You know I’m not aloud to receive messages when I’m in the archives.” Aero replied.
            <br>
            <br>

            “You’re not allowed to communicate with your friends either but look at you go!”
            <br>
            <br>

            “Shut up,” Aero smiled despite herself, “Tell me about this alert since it’s so important.”
            <br>
            <br>

            “Short version, there are free spaces on the Ark and Club Mirai are hosting a competition…”
            <br>
            <br>

            “…and the winners get to go to the Ark!” Aero exclaimed before clamping her hand over her mouth while the echoes of her voice taunted her from all corners of the archives.
            <br>
            <br>

            “Way to steal my thunder, Aero.” Dax replied dryly.
            <br>
            <br>

            “I’m sorry. But that’s super exciting! I take it you’re going?” she said.
            <br>
            <br>

            “Correction. We’re going. You’re one of the founding members of The Delta Aero! What kind of gang representation would we have with only three of the members?”
            <br>
            <br>

            “So Cell and Grim are coming too?” Aero asked.
            <br>
            <br>

            “For a chance to live on the Ark? To work towards expanding the consciousness of all mankind? Of course they are! I mean, I haven’t told Grim yet, but hell yeah, we’re all going.” Dax’s enthusiasm was catching, and Aero was smiling so widely her cheeks were beginning to ache.
            <br>
            <br>

            “You’re the only one with any real issue with getting away…” Dax said, “You know, with that Yorkist sense of responsibility that you have.”
            <br>
            <br>

            Aero rolled her eyes. “I take it we’re meeting at Grim’s?”
            <br>
            <br>

            “You bet. So you’re in?” Dax asked.
            <br>
            <br>

            “As if the three of you have any hope in hell without me.” Aero replied, tapping her temple with her finger as she signed off. She hesitated for just a moment as she took a final look over shoulder to where the tome remained open at the same page, untouched.
            <br>
            <br>

            I’ll be in so much trouble once my mentor returns, but what the hell, it’s The Ark!
            <br>
            <br>

            Aero stepped up onto the window ledge and held her arms out. Raising her head to the clouds she smiled as the wind hurtled towards her with encouragement. Grinning, Aero listened for the slight mechanical whirring as her wings unfurled as and she felt them catch the wind, she leapt off the ledge and soared towards the valley that would lead her from the York Mountains to her friends.
            <br>
            <br>


          </div>
          <h3 style="color: white; font-size: 40px; margin-top: 3vh; width: 84vw; text-align: left;">
            Chapter 4
            <div class="gradient-filler" style="width: 84vw; margin-top: 2vh;" ></div>
          </h3>
          <div  class="text-white whitespace-pre-line paragraph-m " style="width: 84vw; margin-top: 5vh; ">
            Grim spun the staff over his head three times, gaining momentum before he swung it downwards, crouching as he drew it sideways and knocked his opponent off his feet. He spun with the staff, his feet barely touching the concrete floor as he stopped abruptly, the end of the shaft pressed against his opponent’s neck. With a nod, Grim confirmed his win and dismissed his sparring opponent, who scurried to their feet and dashed from the room as though it were on fire.
            <br>
            <br>

            “If only you moved that quickly when you fought!” Grim called out after him, smirking to himself as he walked over the basin against the far wall and splashed cool water over his face. Looking up at the tarnished mirror hanging over it, Grim admired the way his eyes flashed red, and his long black hair stuck out in all directions. His eyes only did that when he was angry or worked up, and he grinned, acknowledging that he’d given himself a sufficient workout. A master combat fighter, he had a reputation to protect, and in a place like Lincoln, reputations could be made and lost again all in a day.
            <br>
            <br>

            Lincoln was the most populated city in all of Mirai, thankfully, in part, to the infamous Club Mirai. Its urban streets were a labyrinth of ruined buildings of the old world, patched up and modified with whatever the locals could get their hands on. The sunshine never reached Lincoln, instead it was the city of eternal night—yet it was never dark. The streets were loaded with neon signs and flashing lights, as everyone tried to cash in on the popularity of the club, offering everything from food to a bed, to licentious entertainments and more. There was something for everyone in Lincoln. If you could stand the crowds.
            <br>
            <br>

            And the gangs.
            <br>
            <br>

            Grim loved everything about Lincoln. At 95% cyborg, and a street fighter, there was no where else in the new world that suited him better. Everyone knew him as strong, fierce, short-tempered, unpredictable and a little violent, which often led less than intelligent folk to question why he wasn’t the leader of The Delta Gang. After he punched them in the face, he’d explain that above all else, he was loyal.
            <br>
            <br>

            Loyal to his gang.
            <br>
            <br>

            Loyal to its members.
            <br>
            <br>

            Loyal to its other founders.
            <br>
            <br>

            Loyal to Dax, their leader—and his best friend.
            <br>
            <br>

            “Saving the best for last?” Grim said as he tapped his temple.
            <br>
            <br>

            “Always.” Dax laughed, “Besides I figured you’d know all about it anyway.”
            <br>
            <br>

            “If you’re talking about the competition at Club Mirai, it’s all anyone is talking about. The streets are growing busier by the hour, if you can believe it.” Grim flopped down on the futon that doubled as both a lounge and his bed and rested his bare feet up on the side table. “I reckon entire population of Mirai is headed for Lincoln.”
            <br>
            <br>

            “Close to it, I’d say.” Dax agreed. “How often do we get the chance to win a place on the Ark?”
            <br>
            <br>

            “There’s going to be fight’s breaking out all over the place.” Grim declared.
            <br>
            <br>

            “You sound more excited about street brawls than the competition itself.” Dax said.
            <br>
            <br>

            Grim shrugged, “Let’s just say I think I have more chance of winning a street fight than a place on the Ark.”
            <br>
            <br>

            “No negative thinking, bro!” Dax chastised him, “There are four places up for grabs. That’s you, me, Cell and Aero.”
            <br>
            <br>

            “You sound confident.”
            <br>
            <br>

            “When aren’t I? I need to be confident for the both of us.” Dax laughed again.
            <br>
            <br>

            “And what happens to The Delta Gang if all its founding members head up into space?”
            <br>
            <br>

            “Then we’ll promote the next round of leaders. Gods know there are plenty to choose from, and all of which would be honoured to take the lead. But let’s cross that bridge when we get to it.” Dax declared.
            <br>
            <br>

            “If.”
            <br>
            <br>

            “Huh?”
            <br>
            <br>

            “You said, when,” Grim said, “and I corrected you to if.”
            <br>
            <br>

            “Whatever, bro. Prepare yourself for company. The three of us will join you as soon as we can.” Dax signed off.
            <br>
            <br>

            Grim got to his feet and picked up the strips of material from the side table and started wrapping his hands as he strode over to the punching bag. All the excited made him want to punch something.
            <br>
            <br>


          </div>
          <h3 style="color: white; font-size: 40px; margin-top: 3vh; width: 84vw; text-align: left;">
            Chapter 5
            <div class="gradient-filler" style="width: 84vw; margin-top: 2vh;" ></div>
          </h3>
          <div  class="text-white whitespace-pre-line paragraph-m " style="width: 84vw; margin-top: 5vh; ">
            Commander Culver pulled her helmet over her head as the air lock closed behind her.
            <br>
            <br>

            “Just for the record, I don’t like this one little bit.” Commander Denver said through the speaker on the other side of the door.
            <br>
            <br>

            “What’s to like? We’re going in blind here.” She replied, locking the helmet into place.
            <br>
            <br>

            “That too, but I was talking about the fact you’re in there and I’m out here.” Denver said.
            <br>
            <br>

            Culver turned and placed her hands on her hips as she glared at him through the small window in the door. “You know how much I hate that macho bullshit, Richard. Just because I’m a woman, doesn’t make me any less competent…”
            <br>
            <br>

            “That’s not what I meant, Mercedes, bloody hell…” he ran his hand through his hair, “Just don’t take any unnecessary risks, okay? I’ll be watching via your cam but at the first sign of anything off, you get back onto this ship, you hear me? The General can get stuffed.” Denver said.
            <br>
            <br>

            Culver rolled her eyes but couldn’t help but smile, “I’d love to be a fly on the wall for that conversation.”
            <br>
            <br>

            “There’s no flies in space, Commander.” Denver smiled back.
            <br>
            <br>

            “Okay, let’s get this over with.” Commander Culver said as she turned her back and moved towards the door lock on the other side of the room. She took a deep breath as she placed her hands on it and carefully opened it, revealing the connecting tunnel that would lead her to where the Aspiration IV was now docked, silent and waiting. They’d been able to take control of the ship to allow them to manually dock it but had been unable to obtain any further information about who—or what—might be awaiting them. They had no idea what had happened to the Aspiration IV, but their communication systems were dead.
            <br>
            <br>

            “You okay, Culver?”
            <br>
            <br>

            “Sure am, Denver. Moving in now, a couple more steps…okay I’ve reached the hatch and I’m opening it.” Culver said, wondering if he could hear her heart thundering against her chest through her mike. There was a slight hissing sound as the hatch opened and darkness greeted her.
            “Did I tell you already how much I don’t like this?” Denver said softly.
            <br>
            <br>

            “Shut up, Denver.” She replied just as quietly as she stepped over the threshold and into the ship. Activating the light on her helmet did little to pierce through the darkness, but it at least allowed her to make sure one foot went in front of the other. She’d never admit it to Commander Denver, but she had sudden flashes of stumbling in the dark over the bodies of dead crew members, and the thought sent a chill down her spine.
            <br>
            <br>

            “I’m entering the main hull of the ship.” She announced quietly, finding some semblance of reassurance that Denver was at the other end, even though she knew it wouldn’t help her if she ran into any real trouble. Slowly, she scanned the room, trying to see as much as she could with the little light she had. Culver had a torch in her pack, but she didn’t want to use it until she knew what they were dealing with—just in case it drew to her any unwanted attention.
            <br>
            <br>

            “Culver?” Denver’s voice spoke into her ear.
            <br>
            <br>

            “All fine here. Nothing seems out of the ordinary so far.” She replied.
            <br>
            <br>

            “You mean aside from the fact the crew appears to have disappeared.” Denver said dryly.
            <br>
            <br>

            “Yeah, aside from that.” Culver stated, as much to herself as to him. The room opened out into three directions, and thanks to NASA, she’d thoroughly memorised the lay out. The branch off to the right would lead to the bridge, the middle branch would lead through the general communal area before branching upwards towards the green station, and downwards for the mechanical station. The far side of the communal area then branched out into the medical and science stations. The last branch would leave her towards the berths, including the captain’s private rooms and the cyrosleep room. First things first, she knew she had to check the bridge.
            <br>
            <br>

            The Commander stepped into the room and gazed at the glass window rising before the bridge. The room was empty.
            <br>
            <br>

            “There’s no one in here. It’s eerie seeing the bridge empty.” She whispered to Denver.
            <br>
            <br>

            “I bet. Leave it for now, you can check over the systems once we make sure there’s no one lying in wait for you.” Denver said in an attempt at humour which only put Carver further on edge. He fell silent as she left the bridge to continue her investigation.
            <br>
            <br>

            “Conducting thermal scan.” Culver said as she moved as soundlessly as she could across the floor. She figured her best chance to get a good read would be from the centre of the communal section—and centre—of the ship. “Nothing detected so far.”
            <br>
            <br>

            “Copy that.”
            <br>
            <br>

            Culver tried to focus on keeping her breathing steady as she made her way along, trying to keep a keen eye on the thermal as well as on her own feet. Finally, she was in place, and she turned slowly to her right, moving the camera along with her as she watched intently.
            <br>
            <br>

            “Anything?” Denver asked.
            <br>
            <br>

            “Nothing yet.” Culver confirmed. She’d almost come full circle when the thermal picked up a heat signature from up ahead. “I think I have something.”
            <br>
            <br>

            “What? Where?” Denver asked.
            <br>
            <br>

            “It looks like it’s from the medical station. I’m going to take a look.” She confirmed.
            <br>
            <br>

            “Be careful.” He warned.
            <br>
            <br>

            “I always am.” It was all Culver could do to keep her pace steady, and it felt like to took minutes, not seconds, to reach the hatch to the medical station.
            <br>
            <br>

            “Hatch is open.” She announced.
            <br>
            <br>

            “Okay. Not unusual. Has the heat signature moved?”
            <br>
            <br>

            “Not so far.” Culver didn’t dare to speak any louder than a whisper, but I can see light. It’s dim, but there’s light.”
            <br>
            <br>

            “Steady, Culver.” Commander Denver said.
            <br>
            <br>

            Culver tucked the thermal away, and drew her weapon—moving or not, she didn’t want any surprises. Walking into the medical station, she looked around the main lab, which was perfectly intact, albeit empty. The light was coming from another room branching off from the far right. She weaved her way across the lab towards the golden glow with equal parts terror and excitement. Pausing at the entrance, she saw it was a ward lined with beds, all of which were empty.
            <br>
            <br>

            Except for one.
            <br>
            <br>

            “There’s someone in the medical ward.” Culver announced as she walked cautiously towards the bed and gasped as she saw who it was. “Denver, it’s Captain Richardson!”
            <br>
            <br>

            “He’s alive! Is he conscious? What are his vitals?” Denver asked in a rush.
            <br>
            <br>

            “Give me a second.” Culver snapped as she ran her gaze over the Commander and the machine he was connected to. It didn’t take her long to realise the Captain of the Aspiration IV was in a coma.
            <br>
            <br>

            “What the hell happened to you, Captain?” Culver murmured, “And where is the rest of your crew?”

          </div>
          <h3 style="color: white; font-size: 40px; margin-top: 3vh; width: 84vw; text-align: left;">
            Chapter 6
            <div class="gradient-filler" style="width: 84vw; margin-top: 2vh;" ></div>
          </h3>
          <div  class="text-white whitespace-pre-line paragraph-m " style="width: 84vw; margin-top: 5vh; ">
            “Grim! You always look so happy to see me!” Dax declared as he arrived at his friend’s apartment.
            <br>
            <br>

            “Where are the others?” Grim asked in greeting.
            <br>
            <br>

            “You two looking for me?” Cell said as he stepped into the room, only slightly sweating from his endurance run.
            <br>
            <br>

            “Of course Aero is the last to arrive.” Grim growled.
            <br>
            <br>

            “It’s a woman’s prerogative to make an entrance.” Aero declared as she hovered down towards the entrance, her feet delicately touching the ground as her wings folded in on themselves.
            <br>
            <br>

            “The Delta founders are in the house!” Whooped Cell.
            <br>
            <br>

            “And keen to kick some gaming butt.” Dax high-fived him.
            <br>
            <br>

            “I sent the word out on the street,” Grim confirmed, “the other gang members will join us as we make our way to the club.”
            <br>
            <br>

            “Win or not, this is going to be epic.” Aero declared as she turned on her heel and led them out onto the street which was already bustling with crowds.
            <br>
            <br>

            “Man, I don’t know how you deal with all these bright lights all the time.” Cell said, raising his arm up dramatically over his eyes as though trying to block out the sun.
            <br>
            <br>

            “Maybe if you spent less time out in your field of flowers, you’d have more appreciation for the city life.” Grim retorted.
            <br>
            <br>

            “You’re forgetting it’s those flowers that allow you drown yourself in neon light day in, day out.” Cell grinned. Grim just shrugged and turned to Dax.
            <br>
            <br>

            “After you.”
            <br>
            <br>

            Dax put two fingers to his forehead in a salute as he turned and led the way. He couldn’t wipe the smile from his face, and as he turned and looked at his friends, he saw they felt the same—except for Grim.
            <br>
            <br>

            “I’m smiling on the inside.” Grim said, and the others laughed.
            <br>
            <br>

            The energy in the streets was palpable, as though the city itself was alive, the streets were its veins, and the people were the lifeforce streaming towards the city’s heart—Club Mirai. Despite the congregating gangs gathering, there were no signs of aggression—everyone was too excited. Their own gang grew exponentially as Delta member fell in line alongside them. Even if someone didn’t know the way to the club, all they would have to do was join the sea of bodies sweeping towards it.
            <br>
            <br>

            Within half an hour, the four friends stood side by side, gazing up at the megalithic structure of shipping containers that was Club Mirai.
            <br>
            <br>

            “Say hello to our futures, gang!” Dax declared, and together, they approached the club.
            <br>
            <br>

            And their destinies.
            <br>
            <br>


          </div>
        </div>
      </div>
    </transition>
  </div>
  <div class="hidden sm:block" >
    <img :src="DaxDesktop" height="100vh" style="position: absolute; z-index: -100;"/>
    <div v-for="role in roles" style="position: relative; z-index: 200; top: 10vh; width: 45vw;">
      <div class="whitespace-pre-line"
           style="font-size: 20px; font-weight: 400; z-index: 500; color: white; margin-left: 3vw; margin-top:  18vh" v-html="role.description">
      </div>
      <div class="flex justify-between flex-wrap"
           style="flex-direction: row; margin-left: 3vw; margin-top: 3vh; width: 100%">
        <div class="tag flex items-center" style="flex-direction: row;">
          <div style="margin-left: 1vw; width: 2vw">
            <img :src="robot" alt="">
          </div>
          <div class="card-description text-white">
            <div style="font-size: 10px">{{role.traits[0].label}}</div>
            <div style="font-size: 18px">{{role.traits[0].value}}</div>
          </div>
        </div>
        <div class="tag flex items-center" style="flex-direction: row;">
          <div style="margin-left: 1vw; width: 2vw">
            <img :src="smile" alt="">
          </div>
          <div class="card-description text-white">
            <div style="font-size: 10px">{{role.traits[1].label}}</div>
            <div style="font-size: 18px">{{role.traits[1].value}}</div>
          </div>
        </div>
        <div class="tag flex items-center" style="flex-direction: row;">
          <div style="margin-left: 1vw; width: 2vw">
            <img :src="alien" alt="">
          </div>
          <div class="card-description text-white">
            <div style="font-size: 10px">{{role.traits[2].label}}</div>
            <div style="font-size: 18px">{{role.traits[2].value}}</div>
          </div>
        </div>
      </div>
    </div>
<!--    <el-carousel height="100vh">
      <el-carousel-item v-for="role in roles" :key="role.name" style="min-height: 100vh">
        <div class="single-page hidden sm:inline-block" :class="role.bg">
          <div style="width: 42vw; float: left">
            <div class="whitespace-pre-line"
                 style="font-size: 20px; font-weight: 400; z-index: 500; color: white; margin-left: 3vw; margin-top:  18vh" v-html="role.description">
            </div>
            <div class="flex justify-between flex-wrap"
                 style="flex-direction: row; margin-left: 3vw; margin-top: 3vh; width: 100%">
              <div class="tag flex items-center" style="flex-direction: row;">
                <div style="margin-left: 1vw; width: 2vw">
                  <img :src="robot" alt="">
                </div>
                <div class="card-description text-white">
                  <div style="font-size: 10px">{{role.traits[0].label}}</div>
                  <div style="font-size: 18px">{{role.traits[0].value}}</div>
                </div>
              </div>
              <div class="tag flex items-center" style="flex-direction: row;">
                <div style="margin-left: 1vw; width: 2vw">
                  <img :src="smile" alt="">
                </div>
                <div class="card-description text-white">
                  <div style="font-size: 10px">{{role.traits[1].label}}</div>
                  <div style="font-size: 18px">{{role.traits[1].value}}</div>
                </div>
              </div>
              <div class="tag flex items-center" style="flex-direction: row;">
                <div style="margin-left: 1vw; width: 2vw">
                  <img :src="alien" alt="">
                </div>
                <div class="card-description text-white">
                  <div style="font-size: 10px">{{role.traits[2].label}}</div>
                  <div style="font-size: 18px">{{role.traits[2].value}}</div>
                </div>
              </div>
            </div>
            <div
                style="margin-top: 20vh; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly">
              <div>
              </div>

            </div>
          </div>

          <div class="avatar-red">
            <div
                style="vertical-align: bottom; width: 50vw; text-align: center; font-family: 'Inter', sans-serif; font-size: 13vw;  color: white; position: relative; top: 10vw; z-index: 0; font-weight: 600; height: 8vw; line-height: 8vw;">
              {{role.name}}
            </div>
            <img :src="role.avatar" alt="" style="z-index: 20; vertical-align: bottom; width: 55vw;  position: relative; bottom: 0;">
          </div>

        </div>
        <div class="bg-dax w-full sm:hidden">

          <div style="width: 82vw; text-align: center; margin: 0 auto">
            <div class="whitespace-pre-line"
                 style="font-size: 20px; font-weight: 400; z-index: 500; color: white; margin:0 auto; text-align: left; padding-top: 5vh" v-html="role.description">
            </div>
            <div class="flex justify-between flex-wrap"
                 style="flex-direction: column; width: 100%">
              <div class="tag-m flex items-center" style="flex-direction: row;">
                <div style="margin-left: 1vw; width: 2rem">
                  <img :src="robot" alt="">
                </div>
                <div class="card-description-m text-white">
                  <div style="font-size: 10px">{{role.traits[0].label}}</div>
                  <div style="font-size: 18px">{{role.traits[0].value}}</div>
                </div>
              </div>
              <div class="tag-m flex items-center" style="flex-direction: row;">
                <div style="margin-left: 1vw; width: 2vw">
                  <img :src="smile" alt="">
                </div>
                <div class="card-description text-white">
                  <div style="font-size: 10px">{{role.traits[1].label}}</div>
                  <div style="font-size: 18px">{{role.traits[1].value}}</div>
                </div>
              </div>
              <div class="tag-m flex items-center" style="flex-direction: row;">
                <div style="margin-left: 1vw; width: 2vw">
                  <img :src="alien" alt="">
                </div>
                <div class="card-description text-white">
                  <div style="font-size: 10px">{{role.traits[2].label}}</div>
                  <div style="font-size: 18px">{{role.traits[2].value}}</div>
                </div>
              </div>
            </div>
            <div
                style="margin-top: 5vh; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly">
              <div>
              </div>

            </div>
          </div>

          <div>
            <div
                style="font-family: 'Inter', sans-serif; font-size: 25vh;color: white; font-weight: 600; height: 15vh; line-height: 15vh; ">
              {{role.name}}
            </div>
            <img :src="role.avatar" alt="" class="w-full" style="margin-bottom: 20px;">
          </div>

        </div>
      </el-carousel-item>
    </el-carousel>-->
  </div>
  <div class="sm:hidden" >
    <img :src="DaxMobile" height="100vh" style="position: absolute; z-index: -100;"/>
    <div v-for="role in roles" style="position: relative; z-index: 200; top: 5vh; width: 85vw;">
      <div class="whitespace-pre-line"
           style="font-size: 16px; font-weight: 400; z-index: 500; color: white; width: 84vw; height: 18vh;overflow: hidden;  text-overflow: ellipsis; margin: 8vh 8vw auto;" v-html="role.description">
      </div>
      <div class="flex justify-between flex-wrap"
           style="flex-direction: row; margin-left: 3vw; margin-top: 2vh; width: 100%">
        <div class="tag-m flex items-center" style="flex-direction: column;">
          <div style="margin-left: 1vw; width: 2vw">
            <img :src="robot" alt="">
          </div>
          <div class="card-description-m text-white">
            <div style="font-size: 10px">{{role.traits[0].label}}</div>
            <div style="font-size: 15px">{{role.traits[0].value}}</div>
          </div>
        </div>
        <div class="tag-m flex items-center" style="flex-direction: column;">
          <div style="margin-left: 1vw; width: 2vw">
            <img :src="smile" alt="">
          </div>
          <div class="card-description-m text-white">
            <div style="font-size: 10px">{{role.traits[1].label}}</div>
            <div style="font-size: 18px">{{role.traits[1].value}}</div>
          </div>
        </div>
        <div class="tag-m flex items-center" style="flex-direction: column;">
          <div style="margin-left: 1vw; width: 2vw">
            <img :src="alien" alt="">
          </div>
          <div class="card-description-m text-white">
            <div style="font-size: 10px">{{role.traits[2].label}}</div>
            <div style="font-size: 18px">{{role.traits[2].value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script >
import { ElMessage } from 'element-plus'
import heroDesktop from '../assets/Hero_Banner_Desktop.png'
import heroDesktopMobile from '../assets/Hero_Banner_Mobile.png'
import DaxDesktop from '../assets/Character Banner_Desktop.png'
export default {
  name: "Lore",
  data() {
    return {
      avatar1: require('../assets/icons/fourBanner.png'),
      card: require('../assets/images/card.png'),
      avared: require('../assets/images/redscar.png'),
      chapt_row: require('../assets/images/chapt-row.jpg'),
      robot: require('../assets/icons/robot.png'),
      smile: require('../assets/icons/smile.png'),
      alien: require('../assets/icons/alien.png'),
      mobilebanner: require('../assets/images/mobilebanner.png'),
      ischapt: true,
      heroDesktop: heroDesktop,
      DaxDesktop: require('../assets/Character Banner_Desktop.png'),
      DaxMobile: require('../assets/Character Banner_Mobile.png'),
      heroDesktopMobile: heroDesktopMobile,
      roles: [

            {
              name: "DAX",
              description: "<b>Leader of Delta</b>.<br><br>He's focused, caring, and leaving no one behind, he's known for his loyalty and intelligence. A pensive and quiet type, rumored to have alien skills. Smokes hand-rolled cigars made from a hometown plant that promotes alertness. A master swordsman skilled in both long and short blades.",
              traits: [
                { label: "Cyborg", value: "70%" },
                { label: "Skin Tone", value: "Red pigmentation" },
                { label: "Heritage", value: "Semi-Alien" },
              ],
              avatar: require('../assets/images/redscar.png'),
              bg: 'bg-dax'
            },
            // {
            //   name: "GRIM",
            //   description: "A sore loser.<br><br>He's exceptionally strong and unbeatable in one-on-one combat. Short-tempered and fierce, he's a force to be reckoned with. A sore loser with an unpredictable and violent nature. Orphaned at a young age and loyal only to Delta. Prefers not to think for himself, acting without thinking and focusing on fighting and gaming. Known to disappear for days at a time.",
            //   traits: [
            //     { label: "Cyborg", value: "95%" },
            //     { label: "Skin Tone", value: "Ashen" },
            //     { label: "Eyes", value: "Red" },
            //   ],
            //   avatar: require('../assets/images/redscar.png'),
            //   bg: 'bg-grim'
            // },
            // {
            //   name: "CELL",
            //   description: "A remarkable example of his race, known for their speed.<br><br>He's exceptionally fast with a cheeky, playful personality. Loves pranks and is known for risk-taking due to a streak of luck. Power comes from a series of core batteries powered by the fission of the Nuke Plants.",
            //   traits: [
            //     { label: "Cyborg", value: "85%" },
            //     { label: "Skin Tone", value: "Purple" },
            //     { label: "Energy Source", value: "Nuke Plant Fission" },
            //   ],
            //   avatar: require('../assets/images/cell.png'),
            //   bg: 'bg-cell',
            // },
            // {
            //   name: "AERO",
            //   description: "Fast, quick-witted, and intelligent peace-maker of Delta.<br><br>Cunning but kind, she has built-in wings enabling ease of travel given the steep inclines of her lands. Her fair skin is complemented by blonde hair with ice-silver streaks and ice-blue eyes. Wings can double as blades if needed.",
            //   traits: [
            //     { label: "Cyborg", value: "90%" },
            //     { label: "Skin Tone", value: "Fair" },
            //     { label: "Special Feature", value: "Wings" },
            //   ],
            //   avatar: require('../assets/images/aero.jpg'),
            //   bg: 'bg-aero'
            // },
          ],


    }
  },
  methods: {
    notification: () => {
      ElMessage("Coming soon")
    }
  }
}



</script>

<style scoped>
body {
  background: #000;
  hyphens: manual;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  *word-wrap: break-word;
  *white-space: normal;
}

.card-description {
  width: 12vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2vw;
}

.card-description-m {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2vw;
}

.gradient-filler {
  margin-top: 7vh;
  width: 25vw;
  height: 0.25vh;
  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
}

.chapter-row-highlighted {
  background: url("../assets/images/chapt-row.jpg") no-repeat;
  background-size: contain;
  margin-left: 15vw;
  width: 40vw;
  height: 25vh;
}
.chapter-word {
  position: relative;
  left: 5vw;
  top: 3vh;
  font-family: 'Inter',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  /* identical to box height, or 120% */
  /* Fill / White */

  color: #FFFFFF;
}
.chapter-row-highlighted-m {
  background: url("../assets/images/mobile-avatar.png") no-repeat;
  background-size: contain;
  width: 80vw;
  height: 20vh;
  margin: auto;
  padding: 2.5vh;
}
.chapter-row-highlighted-m-row {
  background: url("../assets/images/mobile-avatar-row.png") no-repeat;
  background-size: contain;
  width: 65vw;
  height: 14vh;
  margin: auto 2vw;
}
.chapter-row-highlighted-m-row-dark {
  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
  background-clip: text;
  color: transparent;
  width: 45vw;
  height: 10vh;
  margin: auto 2vw;
}

.chapter-row-dark {
  width: 40vw;
  height: 20vh;
  /*border: 4px solid;*/
  /*border-image: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);*/
}


.chapter-word-row {
  position: relative;
  left: 4vw;
  top: 2vh;
  width: 45vw;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /*line-height: 48px;*/
  /* identical to box height, or 120% */
  /* Fill / White */

  color: #FFFFFF;
}
.chapter-word-m {
  position: relative;
  left: 2vw;
  top: 3vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  /* identical to box height, or 120% */
  /* Fill / White */

  color: #FFFFFF;
}

.bg-cover {
  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
  width: 100vw;
  display: flex;
  /*position: absolute;*/
  /*z-index: 0;*/
}

.chapters {
  /*background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);*/
  background: url("../assets/images/chapters.jpg");
  background-size: 100% 100%;
  width: 17vw;
  height: 24vw;
}

.paragraph {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  color: #F5F5F5;
}

.chapter-item {
  width: 14vw;
  height: 3.5vw;

  /* Heading 03 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vw;
  line-height: 48px;
  /* identical to box height, or 120% */


  /* Fill / White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.read-more {
  /* Call to Action */
  width: 12vw;
  height: 2vw;

  /* Paragraph 01 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Fill / White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.bg-dax {
  background: linear-gradient(47.3deg, #DB97AE 0%, #FFBF85 127.58%);
}
.bg-cell {
  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
}
.bg-grim {
  background: linear-gradient(47.3deg, #DB97AE 0%, #7bd718 127.58%);
}
.bg-aero {
  background: linear-gradient(47.3deg, #b71049 0%, #FFBF85 127.58%);
}
.single-page {
  width: 100vw;
  height: 100vh;
}

.year {
  position: absolute;
  top: 35vh;
  left: 50vw;
  width: 100vw;
  text-align: center;
  /*margin: 40vh auto;*/
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 15rem;
  line-height: 15rem;
  /* identical to box height, or 38% */

  text-transform: capitalize;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.story {
  margin: 10vh auto;
  width: 80vw;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 350;
  font-size: 42px;
  line-height: 58px;
  paragraph-spacing: 16;
  /* or 133% */
  text-align: left;
}
.story_m {
  margin: 10vh auto;
  width: 80vw;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 350;
  font-size: 26px;
  line-height: 128px;
  /* or 133% */
  text-align: left;
}
p {
  line-height: 16px;
}
.advertising-outdoors {
  /* Advertising Outdoors */

  /*width: 852px;*/
  /*height: 192px;*/
  /*left: 147px;*/
  /*top: 397px;*/

  /* Heading 01 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 88px;
  /* or 110% */


  /* Artwork Backgrounds/Hope */

  background: linear-gradient(159.84deg, #9CBDFF -51.12%, #9DBDFF -29.59%, #9FBCFD -12.07%, #A3BCFB 2.05%, #A8BCF9 13.41%, #AEBBF6 22.6%, #B5BAF2 30.25%, #BDBAEF 36.98%, #C5B9EB 43.39%, #CDB8E6 50.12%, #D6B7E2 57.77%, #DFB7DD 66.96%, #E7B6D9 78.31%, #F0B5D5 92.44%, #F8B4D1 109.96%, #FFB4CD 131.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  mix-blend-mode: normal;
  opacity: 0.9;
}

.fourGuys {
  margin: 0 auto;
  width: auto;
  height: auto;
  /*max-width: 90vw;*/
  /*max-height: 40vw;*/
  align-self: flex-end;
  overflow-y: hidden;
}

.tag {
  width: 19vw;
  margin-right: 2vw;
  margin-top: 4vh;
  height: 10vh;
  background: #292929;
}

.tag-m {
  width: 70vw;
  margin: 2vh auto;
  height: 10vh;
  background: #292929;
}

.avatar-red {
  position: relative;
  float: right;
  margin-right: 10vw;
  bottom: 0;
  height: 55vw;
  /*display: table-cell;*/
  vertical-align: bottom;
  width: 45vw;
}
</style>
<style>
.el-carousel {
  min-height: 100vh;
}
</style>

<template>
<!--  <div class="bg-black single-page">-->
<!--    <div class="time text-center content-center top-1/2 left-1/2 ">404</div>-->
<!--  </div>-->
  <div class="single-page bg-white">
    <p class="roadmap relative top-1/3 left-18 "> Roadmap to <br> #insane value</p>
    <div class="bg-gray-400 mx-auto w-1/3"></div>
  </div>
  <h1>What you should know</h1>
  <div class="collapse m-4">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item title="Consistency" name="1">
        <div>
          Consistent with real life: in line with the process and logic of real
          life, and comply with languages and habits that the users are used to;
        </div>
        <div>
          Consistent within interface: all elements should be consistent, such
          as: design style, icons and texts, position of elements, etc.
        </div>
      </el-collapse-item>
      <el-collapse-item title="Feedback" name="2">
        <div>
          Operation feedback: enable the users to clearly perceive their
          operations by style updates and interactive effects;
        </div>
        <div>
          Visual feedback: reflect current state by updating or rearranging
          elements of the page.
        </div>
      </el-collapse-item>
      <el-collapse-item title="Efficiency" name="3">
        <div>
          Simplify the process: keep operating process simple and intuitive;
        </div>
        <div>
          Definite and clear: enunciate your intentions clearly so that the
          users can quickly understand and make decisions;
        </div>
        <div>
          Easy to identify: the interface should be straightforward, which helps
          the users to identify and frees them from memorizing and recalling.
        </div>
      </el-collapse-item>
      <el-collapse-item title="Controllability" name="4">
        <div>
          Decision making: giving advices about operations is acceptable, but do
          not make decisions for the users;
        </div>
        <div>
          Controlled consequences: users should be granted the freedom to
          operate, including canceling, aborting or terminating current
          operation.
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {ref} from "vue";

const activeName = ref('1')
export default {
  name: "Utilities",

}
</script>

<style scoped>

.time {
  /* 24:58:59 */
  position: absolute;
  /*margin: 40vh auto;*/
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 25vh;
  line-height: 25vh;
  /* identical to box height, or 38% */

  text-transform: capitalize;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
.roadmap {
  /* Heading 1 / Regular */

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 100px;
  /* or 143% */

  letter-spacing: -1px;

  /* Fill / Black */

  color: #191919;
}

.single-page {
  width: 100vw;
  height: 100vh;
}
</style>
<style>
.el-collapse {
  width: 50vw;
  font-size: 50px;
}
.el-collapse-item {
  font-size: 30px;
}
</style>


import {defineComponent, computed, ref} from 'vue';
import {useRoute} from 'vue-router';
import NavButton from '@/components/NavButton.vue';
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {BellIcon, MenuIcon, XIcon} from '@heroicons/vue/outline'
export default defineComponent({
  components: {NavButton},

  setup() {
    const route = useRoute();
    const path = computed(() => route.path);
    let showsidemenu = ref(false);
    const menu = [
      {name: 'The Lore', url: '/lore', current: true},
      {name: 'Roadmap', url: '/ecosystem', current: true},
      // {name: 'Team', url: '/team', current: true},
      // {name: 'Community', url: '/community', current: false},
      // {name: 'Utilities', url: '/utilities', current: false},
      {name: 'FAQ', url: '/faq', current: false},
      // { name: '/', url: '/' },
      // { name: 'FARM MANAGER', url: '/manager' },
      // {name: 'STAKING', url: '/staking', current: false},
    ];

    const handleOpen = (key: string, keyPath: string[]) => {
      console.log(key, keyPath)
    }
    const handleClose = (key: string, keyPath: string[]) => {
      console.log(key, keyPath)
    }

    function openMenu() {
      showsidemenu.value = true;
      // alert('aaa')
    }

    return {
      menu,
      path,
      showsidemenu,
      handleOpen,
      handleClose,
      openMenu
    };
  },

});



import { defineComponent, onMounted, ref, watch } from 'vue';
import useWallet from '../../composables/wallet';
import useCluster from '../../composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import { BN } from '@project-serum/anchor';
import { RewardType } from '@gemworks/gem-farm-ts';

export default defineComponent({
  emits: ['new-farm'],
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    let gf: any;
    watch([wallet, cluster], async () => {
      gf = await initGemFarm(getConnection(), getWallet()!);
    });

    //needed coz mounts later
    onMounted(async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
      }
    });

    // --------------------------------------- init farm
    const mintA = ref<string>();
    const typeA = ref<any>(RewardType.Variable);
    const mintB = ref<string>();
    const typeB = ref<any>(RewardType.Fixed);

    const minStakingPeriodSec = ref<string>();
    const cooldownPeriodSec = ref<string>();
    const unstakingFeeLamp = ref<string>();

    const initFarm = async () => {
      const { farm } = await gf.initFarmWallet(
        new PublicKey(mintA.value!),
        typeA.value,
        new PublicKey(mintB.value!),
        typeB.value,
        {
          minStakingPeriodSec: new BN(minStakingPeriodSec.value!),
          cooldownPeriodSec: new BN(cooldownPeriodSec.value!),
          unstakingFeeLamp: new BN(unstakingFeeLamp.value!),
        }
      );

      ctx.emit('new-farm', farm.publicKey.toBase58());
    };

    return {
      wallet,
      RewardType,
      mintA,
      typeA,
      mintB,
      typeB,
      minStakingPeriodSec,
      cooldownPeriodSec,
      unstakingFeeLamp,
      initFarm,
    };
  },
});

<template>
  <div class="homepage-bg"></div>
  <div class="time text-center content-center left-1/2 ">
    <countdown style="font-size: 18vw;  font-weight: 100;" :time="timeDifference" />
  </div>
</template>

<script>
import {defineComponent, provide} from 'vue'
import Countdown from 'vue3-countdown'
export default defineComponent({
  components: {
    Countdown
  },
  setup() {
    // const deadLine = ref('2021/12/06 10:00:00 GMT-0200')
    // provide('deadline', deadLine)

    return {
      // const deadLine = ref('2021/12/06 10:00:00 GMT-0200')
    }
  },
  data() {
    return {
      // ... other data properties
      destination: new Date(2023, 3, 30, 10, 0, 0),
    };
  },
  computed: {
    timeDifference() {
      const currentTime = new Date();
      return this.destination - currentTime;
    },
  },

})
</script>

<style scoped>
.time {
  position: relative;
  font-family: 'Inter',sans-serif;
  top: 45vh;
  line-height: 14rem;
  text-transform: capitalize;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
.homepage-bg {
  position: absolute;
  z-index: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background: url("../assets/images/cover.jpg") 100% 100% no-repeat;
  background-size: auto;
}
</style>
<style>
* {
  margin: 0;
  padding: -8px;
}

</style>

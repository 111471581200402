<template>
  <div>
    <TheNavBar class="" />
<!--    <div class="bg" v-if="this.$route.path === '/'"></div>-->
    <router-view></router-view>
<!--    <div class="pt-10 px-10 flex justify-center align-middle">-->
<!--      <p class="text-6xl pt-3 px-1 mt-1 text-black">青い Mirai</p>-->
<!--    </div>-->
<!--    <div class="italic mt-5 text-center">by xxx</div>-->
<!--    <div class="align-middle justify-center">-->
<!--    <div class="black-glass pt-10 px-10 w-128 h-24 text-indigo-50 text-center 8xl:font-sans ">-->
<!--      this is WIP, please only use burner wallet to connect to dev!-->
<!--    </div>-->
<!--    </div>-->
<!--    <div class="mt-5 text-center">this is WIP, please only use burner wallet to connect to dev!</div>-->

<!--    <div class="p-10">-->
<!--      <router-view />-->
<!--    </div>-->

<!--    <div class="pt-10"></div>-->
<!--    <TheCat />-->
<!--    <SideNav class="hidden lg:block"></SideNav>-->
<!--    <TheFooter />-->
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
import TheFooter from '@/components/gem-farm/TheFooter';
import TheCat from '@/components/gem-farm/TheCat';
import SideNav from '@/components/SideNav';
export default {
  components: { TheCat, TheFooter, TheNavBar, SideNav},
};
</script>

<style>
* {
  font-family: Inter, sans-serif !important;
  margin: 0;
  padding: 0;
  cursor: default;
}
*:active {
  cursor: default;
}
a {
  cursor: default;
}
input[type='radio']:checked + span {
  @apply text-black;
}
.black-glass {
  background: rgba(36, 33, 33, 0.25);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(36, 33, 33, 0.3);
}
.bg {
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background: url("assets/images/cover.jpg") no-repeat;
}
html {
  padding: 0;
  margin: 0;
}
</style>

<template>
<!--  <div class="side-nav hidden lg:block" >-->
<!--    <div class="nav-font">Gallary</div>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "SideNav"
}
</script>

<style scoped>
.side-nav {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*gap: 8px;*/
  /*color: white;*/
  z-index: 100;
  position: fixed;
  width: 3vw;
  height: 100vh;
  left: 97vw;
  top: 0px;
  background: #F626B0;
}
.side-nav:hover {
  /* transfer the color to blue in 1 sec */
  background: #05B5EC;
  transition: 1s;
}
.nav-font {
  color: white;
  width: 60px;
  height: 6vw;
  text-align: left;
  font-family: 'Inter',serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 6vw;
  transform: rotate(-90deg);

  /* identical to box height, or 156% */

  /*text-align: right;*/
}
</style>

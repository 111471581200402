<template>
  <div class="footer pt-1 pb-1">
    <p class="px-3">mirai @2022</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  @apply w-full flex justify-center align-middle bg-gray-200 text-center;
  position: absolute;
  bottom: 0px;
  height: 40px;
}
</style>
